import React, { useContext, useEffect, useState,useRef } from "react";
import "../../styles/Feed.css";
import Chat from "../chat/Chat";
import Message from "../chat/Message";
import { DataContext } from "../DataContext";
import PopUp from "../PopUp";
import ScrollButton from "../ScrollButton";
import FeedHeader from "./FeedHeader";
import Post from "./Post";
import axios from "axios";


const AlwaysScrollToBottom = () => {
	const elementRef = useRef();
	useEffect(() => elementRef.current.scrollIntoView());
	return <div ref={elementRef} />;
  };

function Feed() {
	const {
		imgData,
		progress,
		handleImgChange,
		LoadingToUpload,
		uploadImage,
		posts,
		users,
		currentUserData,
		componentsSearchCondition,
		setComponentsSearchCondition,
		getCurrentUserID,
		openChatPopUp,
		closeChatPopUp,
		conversationPartner,
		setConversationPartner,
		showPopUp,
		setShowPopUp,
		socket,
		room,
		setRoom,
		messages,
		setMessages,
		getPopularPosts,
		handlePostTime,
		chats,
		setChats,
		onlineUsers
	} = useContext(DataContext);
	const [postsToRender, setPostsToRender] = useState(posts);
	const [direction, setDirection] = useState("ltr");

	// function messagesOrganize() {
	// 	let resultMessages = [];
	// 	let bodyConcate = "";
	// 	let i = 0;
	// 	if(messages.length<1)
	// 	return
	// 	while (i < messages.length) {
	// 		while (i < messages.length&&messages[i].senderId == currentUserData.id) {
	// 			bodyConcate += messages[i].body;
	// 			i++;
	// 			if (i < messages.length&&messages[i].senderId != currentUserData.id) {
	// 				resultMessages.push({
	// 					senderId: messages[i - 1].senderId,
	// 					body: bodyConcate,
	// 				});
	// 				bodyConcate = "";
	// 			}
	// 			if(i==messages.length){
	// 				resultMessages.push({
	// 					senderId: messages[i - 1].senderId,
	// 					body: bodyConcate,
	// 				});
	// 			}
	// 		}
	// 		while (i < messages.length&&messages[i].senderId != currentUserData.id) {
	// 			bodyConcate += messages[i].body;
	// 			i++;
	// 			if (i < messages.length&&messages[i].senderId == currentUserData.id) {
	// 				resultMessages.push({
	// 					senderId: messages[i - 1].senderId,
	// 					body: bodyConcate,
	// 				});
	// 				bodyConcate = "";
	// 			}
	// 			if(i==messages.length){
	// 				resultMessages.push({
	// 					senderId: messages[i - 1].senderId,
	// 					body: bodyConcate,
	// 				});
	// 			}

	// 		}

	// 	}
	// 	setMessages(resultMessages)
	// }

	function gotoBottom(className) {
		var element = document.getElementsByClassName(className)[0];
	
			element.scrollTop = element.scrollHeight;
		
	}
	function checkRTL(s) {
		var ltrChars =
				"A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
				"\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
			rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
			rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");
		return rtlDirCheck.test(s);
	}
	useEffect(() => {
		gotoBottom("chat_popup_board");
	}, [messages]);

	useEffect(() => {
		setPostsToRender(posts);
		//reset the search condition
		setComponentsSearchCondition((prev) => (e) => e);
	}, [posts]);

	useEffect(() => {
		socket.on("receive_message", (data) => {
			setMessages((list) => [...list, data]);
		});
	
	}, [socket]);
	useEffect(() => {
	}, [messages]);

	return (
		<div className="feed_container">
			<ScrollButton />
			<FeedHeader
				handleImgChange={handleImgChange}
				progress={progress}
				imgData={imgData}
				LoadingToUpload={LoadingToUpload}
				uploadImage={uploadImage}
				setPostsToRender={setPostsToRender}
				posts={posts}
				getCurrentUserID={getCurrentUserID}
			/>
			{users &&
				postsToRender
					.filter(
						componentsSearchCondition
							? componentsSearchCondition
							: (element) => element
					)
					.filter((post) => handlePostTime(post.publishDate) > 0)
					.filter(
						!isNaN
						(
							window.location.pathname.split('/')
							[window.location.pathname.split('/').length-1]
						)
						
						?
						post=> 
						post.id 
						==
						window.location.pathname.split('/')
						[window.location.pathname.split('/').length-1] 
						:
						post=>post
							)
					.reverse()
					.map((post) => {
						let postUser = users.find(
							(user) => user.id == post.postUserId
						);
						return (
							<Post
								currentPostUser={postUser}
								profileImg={post.profileImg}
								contentImg={post.imageSource}
								content={post.description}
								date={post.publishDate}
								cheers={post.cheers}
								postId={post.id}
								currentUserData={currentUserData}
								
							/>
						);
					})}

			<Chat
				onlineUsers={onlineUsers}
				room={room}
				setRoom={setRoom}
				socket={socket}
				setMessages={setMessages}
				messages={messages}
				openChatPopUp={openChatPopUp}
				setConversationPartner={setConversationPartner}
			/>
			{/* chat pop up */}
			<PopUp
				name={conversationPartner ? conversationPartner["name"] : ""}
				imgSrc={
					conversationPartner ? conversationPartner["imgSrc"] : ""
				}
				rankImg={
					conversationPartner ? conversationPartner["rank"] : "Bronze"
				}
				content={
					<div className="chat_popup_container">
						
						<div className="chat_popup_board">
							{messages
								.filter((msg) => {
									let currentChat = {};
									if (conversationPartner)
										currentChat = chats.find(
											(chat) =>
												chat.user2Id ==
													conversationPartner.id ||
												chat.user1Id ==
													conversationPartner.id
										);
									if (currentChat)
										return (
											currentChat.id == msg.chatId ||
											isNaN(msg.chatId)
										);
									else return false;
								})
								.map((msg) => {
									
									return (
										<div>
											<Message
												currentUserData={
													currentUserData
												}
												profileImg={
													currentUserData.profileImage
												}
												partnerProfileImg={
													conversationPartner
														? conversationPartner[
																"imgSrc"
														  ]
														: ""
												}
												content={msg.body}
												who={msg.senderId}
												direction={direction}
											/>
											<p
												style={{
													float:
														msg.senderId ==
														currentUserData.id
															? "left"
															: "right",
													marginTop: "5px",
													fontFamily: "tahoma",
													color: "gray",
													fontSize: "11px",
												}}>
												{new Date(
													msg.messageCreation
												).toLocaleString()}
											</p>
										</div>
									);
								})}
								<AlwaysScrollToBottom />
						</div>
						<div
							className="chat_popup_create_message"
							style={{
								backgroundColor: "var(--blue)",
								padding: "10px",
							}}>
							<textarea
								onKeyPress={async (e) => {
									let messageOnlineData = {
										body: e.target.value,
										senderId: currentUserData.id,
										room: room,
										chatId: '&'+currentUserData.id+conversationPartner.id,
										isRead:0,
										messageCreation: new Date(),
									};
									if (e.key == "Enter") {
										e.preventDefault();

										if (e.target.value.length > 0) {
											setMessages((prev) => [
												...prev,
												messageOnlineData,
											]);
											await socket.emit(
												"send_message",
												messageOnlineData
											);

											axios
												.post(
													"https://champiz-server.herokuapp.com/chat/newMessage",
													{
														userName:
															conversationPartner.name,
														body: messageOnlineData.body,
														id: currentUserData.id,
													},
													{
														headers: {
															"access-token":
																localStorage.getItem(
																	"access-token"
																),
														},
													}
												)
												.then((res) => {
													if (
														!chats.find(
															(chat) =>
																chat.user1Id ==
																	conversationPartner.id ||
																chat.user2Id ==
																	conversationPartner.id
														)
													)
														axios
															.get(
																"https://champiz-server.herokuapp.com/chat/getChats",
																{
																	headers: {
																		id: currentUserData.id,
																		"access-token":
																			localStorage.getItem(
																				"access-token"
																			),
																	},
																}
															)
															.then((res) => {
																setChats(
																	res.data
																);
															});
												});
										}

										e.target.value = "";
										console.log(messages);
									}
								}}
								style={{ direction: direction }}
								onInput={(s) =>
									checkRTL(s.target.value)
										? setDirection("rtl")
										: setDirection("ltr")
								}
								className="chat_popup_message_input"
								cols="20"
								rows="4"></textarea>
						</div>
					</div>
				}
				openChatPopUp={openChatPopUp}
				showPopUp={showPopUp}
			/>
			<div
				onClick={closeChatPopUp}
				className="blur_background"
				style={
					showPopUp ? { display: "flex" } : { display: "none" }
				}></div>

			<div
				className="control_button"
				onClick={() =>
					getPopularPosts(currentUserData.id).then((res) =>
						setPostsToRender(res.data)
					)
				}>
				<button className="button">
					<div className="blob">
						<svg
							xmlnsXlink="http://www.w3.org/1999/xlink"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 310 350">
							<path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z" />
						</svg>
					</div>
					<div className="line"></div>
					<div className="text">
						<p className="shakingP">P</p>
						<p className="shakingP">O</p>
						<p className="shakingP">P</p>
						<p className="shakingP">U</p>
						<p className="shakingP">L</p>
						<p className="shakingP">A</p>
						<p className="shakingP">R</p>
					</div>
				</button>
			</div>

			<div
				className="control_button followers_control"
				onClick={() => {
					setPostsToRender(posts);
				}}>
				<button className="button">
					<div className="blob">
						<svg
							xmlnsXlink="http://www.w3.org/1999/xlink"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 310 350">
							<path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z" />
						</svg>
					</div>
					<div className="line"></div>
					<div className="text">
						<p className="shakingP followers_control_p">F</p>
						<p className="shakingP followers_control_p">R</p>
						<p className="shakingP followers_control_p">I</p>
						<p className="shakingP followers_control_p">E</p>
						<p className="shakingP followers_control_p">N</p>
						<p className="shakingP followers_control_p">D</p>
						<p className="shakingP followers_control_p">S</p>
					</div>
				</button>
			</div>
		</div>
	);
}

export default Feed;
