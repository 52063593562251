import { useContext,useEffect } from "react";
import React from "react";
import { DataContext } from "../DataContext";
import { Image } from "cloudinary-react";
function Notification({ date, body, link }) {
	const { currentUserData } = useContext(DataContext);
	useEffect(()=>{
		console.log(link)
	},[])
	return (
		<a href={`https://www.champiz.com/${link}`}>
			<div className="notifications_dropdown_node">
				<Image
					className="notifications_dropdown_node_img"
					cloudName="champiz"
					publicId={"notfication_yi4hlh"}
					alt=""
				/>
				<div className="notifications_dropdown_node_content_container">
					<p className="notifications_dropdown_node_body">{body}</p>
					<p className="notifications_dropdown_node_date">{date}</p>
				</div>
			</div>
		</a>
	);
}

export default Notification;
