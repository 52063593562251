import React,{useState} from 'react'
import axios from 'axios'
export default function Description({content,checkProfileUser,userId}) {
	const [newDescription,setNewDescription] = useState(content)
	function changeDescription(e){
		let newContent = prompt("enter new description")
		newContent = newContent==""?"Champiz rulezzz":newContent;
		setNewDescription(newContent)
		axios
			.put("https://champiz-server.herokuapp.com/users/updateProfileDescription",{
				id:userId,
				profileDescription:newContent
			}, 
			{
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			})
	}
	return (
		<div className="profile_description_container">
		<div className="profile_description_info">
			<p>{newDescription?newDescription:content}</p>
		</div>
		<button onClick={changeDescription} style={{display:checkProfileUser(userId)?'block':'none'}}>Edit</button>	
		</div>
	)
}
