import React, { useEffect } from 'react'
import '../styles/ScrollButton.css'
import {IoIosArrowUp} from 'react-icons/io'
function ScrollButton() {
	

function scrollFunction() {
	const scrollBtn = document.getElementById('ScrollButton_btn')
	if( scrollBtn==null)
	return
  if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
    scrollBtn.style.display = "flex";
  } else {
    scrollBtn.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
function topFunction() {
	
	const scrollInterval = setInterval(()=>{
		document.body.scrollTop -= 30;
	document.documentElement.scrollTop -= 30;
	if( document.documentElement.scrollTop==0)
		clearInterval(scrollInterval)
	},0)
	
}
  
useEffect(()=>{
// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};
},[])
	return (
		<div onClick={topFunction} id="ScrollButton_btn">
			<p>UP</p>
			<IoIosArrowUp size={30} />
		</div>
	)
}

export default ScrollButton
