import React, { useEffect, useContext, useState } from "react";
import Comment from "./Comment";
import { Image } from "cloudinary-react";
import Cheer from "../Cheer";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { DataContext } from "../DataContext";
function Post({
	profileImg,
	contentImg,
	content,
	date,
	cheers,
	currentPostUser,
	postId,
	isAdmin,
}) {
	const [moreInfo, setMoreInfo] = useState(false);
	const [commentContent, setCommentContent] = useState("");
	const [comments, setComments] = useState([]);
	const [cheersToPresent, setCheersToPresent] = useState(cheers);
	const [wasCheers, setWasCheers] = useState(localStorage.getItem("likes")?.includes(postId+',')?true:false);
	const { users, checkStringLength,currentUserData,handlePostTime,setPosts,getAllPosts } = useContext(DataContext);
	function handleMoreInfo() {
		setMoreInfo((prev) => !prev);
	}
	function onCommentChange(e) {
		setCommentContent(e.target.value);
	}
	function handleWithCheers(id,postId,action) {
		if(!localStorage.getItem("likes")?.includes(postId)&&action=='cheersPlus'){
			localStorage.setItem("likes",postId+","+localStorage.getItem('likes'))
		}
		if(action=='cheersMinus')
		localStorage.setItem('likes',localStorage.getItem("likes").replace(postId+',',''))

		setWasCheers((prev) => !prev);
		if(cheersToPresent<=0&&action=='cheersMinus')
		return;
		setCheersToPresent(prev=>action=='cheersPlus'?prev+1:prev-1)
		axios
			.put(
				`https://champiz-server.herokuapp.com/posts/${action}`,
				{
					postId: postId,
					sourceId: id,
				},
				{
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				}
			)
			.then((res) =>
				console.log(res.data))	
			.catch(err=>alert(err))
			
	}
	function createComment() {
		axios
			.post(
				"https://champiz-server.herokuapp.com/comments/newComment",
				{
					body: commentContent,
					postId: postId,
					commentUserId: currentUserData.id,
				},
				{
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				}
			)
			.then((res) =>
			axios
			.get("https://champiz-server.herokuapp.com/comments/getComments", {
				headers: {
					postId: postId,
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then(
				(res) => {
					setComments(res.data)})
					.catch(e=> console.log(e))
			)
			.then((res) => setCommentContent(""));
	}

	useEffect(() => {
		
		axios
			.get("https://champiz-server.herokuapp.com/comments/getComments", {
				headers: {
					postId: postId,
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then(
				(res) => {
					setComments(res.data)})
					.catch(e=> console.log(e))
		
			
	}, []);
useEffect(()=>{
	setCheersToPresent(cheers)
},[cheers])

	return (
		<div  className={'post_container'} id={isAdmin?'admin_post_container':''}>
				<div
						onClick={()=>{
							if(window.confirm("Are you sure you want to delete this post?")){
								axios.delete(`https://champiz-server.herokuapp.com/posts/delete/${postId}`, {
									headers: {
										"access-token": localStorage.getItem("access-token")
									}
								}
							).then(res=>{
								getAllPosts()
								.then(res=>setPosts(res.data))
							})

							}
						}}
						style={{
							position:'absolute',
							top:-15,
							right:-15,
							width:'30px',
							height:'30px',
							borderRadius:20,
							backgroundColor:'red',
							fontFamily:'input_font',
							textAlign:'center',
							color:'white',
							border:'2px solid var(--blue)',
							display:isAdmin?'block':'none',
							cursor:'pointer'
						}}>
							X
						</div>
			{currentPostUser && (
				<div className="post_header">
					<img
						className={
							currentPostUser.userRank == "Platinum" ||
							currentPostUser.userRank == "Bronze" ||
							currentPostUser.userRank == "Silver"
								? "post_header_wide_img"
								: "post_header_img"
						}
						src={
							require(`../../images/ranking/${currentPostUser.userRank}.svg`)
								.default
						}
						alt="rank"
						style={{ marginLeft: "5px" }}
					/>
					<div className="post_header_profile_container">
						<div className="circle_container_mid">
							<div className="circle">
								<Image
									className="circle_Image"
									publicId={currentPostUser.profileImage}
									cloudName={"Champiz"}
								/>
							</div>
						</div>
						<a
							className="post_header_profile_hyperlink"
							href={`/profile${currentPostUser.id}`}>
							{" "}
							<p style={{ color:isAdmin?'black':'--var(yellow)',marginLeft: "10px" }}>
								{currentPostUser.username}
							</p>
						</a>
					</div>
					<div className="post_header_profile_container">
						<span className="post_time"  style={isAdmin?{color:'black'}:{}}>{handlePostTime(date)+'h'}</span>
						<img
							className="clock_icon"
							src={require("../../images/clock.svg").default}
						/>
					</div>
				</div>
			)}
			<Image
				id={isAdmin?'admin_post_img':''}
				className="post_content_img"
				cloudName="champiz"
				publicId={contentImg}
				alt=""
			/>
			<div className="post_interaction">
				<div className="post_message_cheers" style={{display:isAdmin?'none':'flex'}}>
					<img
						className="post_content_img_icons"
						onClick={handleMoreInfo}
						src={require("../../images/message.svg").default}
						alt=""
					/>
					<Cheer  postCheer={handleWithCheers} action={wasCheers?'cheersMinus':'cheersPlus'} id={currentPostUser.id} postId={postId}/>
				</div>
				<div className="post_cheers_count">
					<img
						src={require("../../images/cheers.svg").default}
						alt=""
					/>
					<p  className="cheers_count" style={isAdmin?{color:'black'}:{}}>{cheersToPresent}</p>
				</div>
			</div>
			<p className="post_content"  style={isAdmin?{color:'black'}:{}} >{content}</p>

			{moreInfo ? (
				<div >
					<div className="post_comments" id={isAdmin?'admin_post_comments':''}>
						{comments.map((comment) => (
							<Comment
							currentUserData={currentUserData}
								isAdmin={isAdmin}
								checkStringLength={checkStringLength}
								content={comment.body}
								cheers={comment.cheers}
								commentId={comment.id}
								user={
									users.find(
										(user) =>
											comment.commentUserId == user.id
									)
								}
							/>
						))}
					</div>
					<div className="post_comment_input_container">
						<textarea
							value={commentContent}
							onChange={onCommentChange}
							type="text"
							className="post_comment_input"
						/>
						<Button
							onClick={createComment}
							className="post_btn_send"
							style={{
								height: "60px",
								marginTop: "2%",
								marginLeft: "10px",
								marginRight: "5px",
							}}
							variant="contained"
							color="disabled"
							startIcon={
								<SendIcon style={{ fontSize: "35px" }} />
							}></Button>
					</div>
					<div className="post_more_info" onClick={handleMoreInfo}>
						<div className="post_more_info_icon_container">
							<IoIosArrowDropupCircle
								className="post_more_info_icon"
								size={35}
								color="White"
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="post_more_info" onClick={handleMoreInfo}>
					<div className="post_more_info_icon_container">
						<IoIosArrowDropdownCircle
							className="post_more_info_icon"
							size={35}
							color="White"
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default Post;
