import React, { useEffect } from "react";
import { useState, useContext } from "react";
import axios from "axios";
import { DataContext } from "../DataContext";
import { Image } from "cloudinary-react";
import Whirligig from "react-whirligig";

export default function MyGallery({userId}) {
	const { userPosts,fetchUserPosts,currentUserData,setPosts } = useContext(DataContext);
	const [currentImg, setCurrentImg] = useState("");
	const [currentImgIndex, setCurrentImgIndex] = useState(0);
	const [popImage, setPopImage] = useState("none");

	let whirligig;
	const next = () => {
		setCurrentImg(userPosts[(currentImgIndex+1)%(userPosts.length)].imageSource)
		setCurrentImgIndex(prev=>(prev+1)%((userPosts.length)))
	};
	const prev = () => {
		setCurrentImg(userPosts[(currentImgIndex>0?currentImgIndex-1:userPosts.length-1)].imageSource)
		setCurrentImgIndex(prev=>(prev>0?prev-1:userPosts.length-1))
	};

	const popHandle = () => {
		setPopImage((prev) => (prev == "none" ? "block" : "none"));
	};

	useEffect(()=>{
		fetchUserPosts(userId)
	},[userId])

	return (
		<div>
			<Whirligig
				visibleSlides={userPosts.length<3?2:3}
				gutter={userPosts.length<3?'-25em':'0.7em'}
				ref={(_whirligigInstance) => {
					whirligig = _whirligigInstance;
				}}>
				{userPosts.map((img,index) => (
					<div style={{
						display:'flex',
						justifyContent:'center',
						alignItems:'center',
						width:'400px',
						border:'1px solid black',
						height: "400px",
						backgroundColor:"white"
					}}>
						<div
						onClick={()=>{
							if(window.confirm("Are you sure you want to delete this post?")){
								axios.delete(`https://champiz-server.herokuapp.com/posts/delete/${img.id}`, {
									headers: {
										"access-token": localStorage.getItem("access-token")
									}
								}
							).then(res=>{
								fetchUserPosts(userId)
								axios
								.get("https://champiz-server.herokuapp.com/posts/followingPosts", {
									headers: {
										id: currentUserData.id,
										"access-token":
											localStorage.getItem("access-token"),
									},
								})
								.then((res) => setPosts(res.data))
								.catch((err) => console.log(err));
						
							})

							}
						}}
						style={{
							position:'absolute',
							top:0,
							left:0,
							width:'30px',
							height:'30px',
							backgroundColor:'red',
							fontFamily:'input_font',
							textAlign:'center',
							color:'white',
							border:'2px solid var(--blue)',
							display:currentUserData.id==userId?'block':'none',
							cursor:'pointer'
						}}>
							X
						</div>
						<div style={{position:'absolute',top:0,fontFamily:'input_font'}}>
							<p>{new Date(img.publishDate).toLocaleDateString()}</p>
						</div>
<Image
						cloudName="Champiz"
						onClick={() => {
							popHandle();
							setCurrentImg(img.imageSource);
							setCurrentImgIndex(index)
						}}
						style={{
							height:'300px',
							maxWidth:'350px'
						}}
						publicId={img.imageSource}
					/>
					</div>
					
				)).reverse()}
			</Whirligig>

			<div
				style={{
					position: "fixed",
					top: "5%",
					right: "18.5%",
					width: "60%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignContent: "center",

					display: popImage,
					zIndex: "333",
				}}>
				<div
					className="slides"
					style={{
						position: "absolute",
						width: "65%",
						left: "17%",
						top: "30%",
					}}>
					<button
						onClick={() => {
							prev();
						}}>
						‹
					</button>
					{
						<Image
							style={{
								position: "absolute",
								height: "500px",
								maxWidth: "1000px",
								left: "50%",
								transform: "translate(-50%,-30%)",
							}}
							cloudName="Champiz"
							publicId={currentImg}
						/>
					}

					<button
						onClick={() => {
							next();
						}}>
						›
					</button>
				</div>
					<div
					onClick={() => setPopImage("none")}
					className="close_gallery_button">
					<p>X</p>
				</div>
				
			</div>

			<div
				className="blur_background"
				style={{ display: popImage }}
				onClick={() => setPopImage("none")}></div>

			
		</div>
	);
}
