import React,{useState} from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const useStyles = makeStyles((theme) => ({
	root: {
	  '& > *': {
		margin: theme.spacing(1),
		width: '25ch',
		display:'flex',
		justifyContent:'center',
	  },
	  
	},
	margin: {
		margin: theme.spacing(2),
	  },
	  withoutLabel: {
		marginTop: theme.spacing(3),
	  },
  }));
function PasswordInput({label,handleChange}) {
	const [values, setValues] = useState({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
	  });
	const classes = useStyles();

	const handleChangePassword = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
		handleChange(event)
		console.log("object")
	  };
	
	  const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	  };

	  const handleMouseDownPassword = (event) => {
		event.preventDefault();
	  };
	return (
		<FormControl className={clsx(classes.margin)} variant="outlined">
          <InputLabel style={{fontSize:'14px'}} htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChangePassword('password')}
			name={"password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
	)
}

export default PasswordInput
