import React,{useContext,useEffect} from "react";
import UserManagement from "./UserManagement";
import PostManagement from "./PostManagement";
import Reports from "./Reports";
import Search from ".././Search";
import { DataContext } from ".././DataContext";
function AdminScreen({ screenToPresent,allPosts }) {
	const {setComponentsSearchCondition} = useContext(DataContext)
	function checkWhichScreen(str) {
		switch (str) {
			case "users":
				return <UserManagement />;
			case "feed":
				return <PostManagement allPosts={allPosts} />;
			case "reports":
				return <Reports />;
		}
	}
	useEffect(()=>{
		setComponentsSearchCondition(e=>element=>element)
	},[screenToPresent])
	return (
		<div className="adminScreen_container">
			<div className="adminScreen_wrapper" style={{display:screenToPresent!='reports'?'flex':'none'}}>
				<Search location={screenToPresent}/>
			</div>
			{checkWhichScreen(screenToPresent)}
		</div>
	);
}

export default AdminScreen;
