import React from "react";
import "../styles/Help.css";
function Help() {
	return (
		<div className="help_container">
			<div>
				<h1 className="help_title">New user:</h1>
				<p className="help_label">
					In our website users divided into ranks, a new user starts
					with the lowest rank, bronze .
				</p>
				<p className="help_label">
					user rank determined by is amount of xp.
				</p>
			</div>

			<div>
				<h1 className="help_title">
					There are several ways to get xp points:
				</h1>
				<p className="help_label">
					1)when user upload a new post, every like that his post
					gets, earns him one xp
				</p>
				<p className="help_label">
					2)every post that the user upload earns him one xp{" "}
				</p>
				<p className="help_label">
					3)on every hour that the user is conect to the website,
					earns him one xp
				</p>
			</div>
			<div>
				<h1 className="help_title">The abilities of each rank:</h1>
				<p className="help_label">
					Bronze - can upload up to 3 posts a day.
				</p>
				<p className="help_label">
					Silver - can upload up to 6 posts a day.
				</p>
				<p className="help_label">
					Gold - can upload up to 9 posts a day.
				</p>
				<p className="help_label">
					Platinum - can upload up to 12 posts a day.
				</p>
				<p className="help_label">
					Diamond - can upload up to 15 posts a day.
				</p>
			</div>
			<div>
				<h1 className="help_title">Feed</h1>
				<p className="help_label">
					in feed There is the chat, uploading posts, the posts of the
					people the user is following and the most popular posts in
					the website.
				</p>
				<h1 className="help_title">Ranking</h1>
				<p className="help_label">
					in rankin there are the profiles of all the users and they
					appear by gender/rank.{" "}
				</p>
			</div>
		</div>
	);
}

export default Help;
