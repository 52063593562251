import React, { useEffect } from 'react'
import {Image} from 'cloudinary-react'
function PopUp({name,imgSrc,content,showPopUp,rankImg}) {
	useEffect(()=>{
	},[name])

	return (
		<div className="popup_container" style={showPopUp?{display:'flex'}:{display:'none'}}>
			<div className="popup_top" style={{backgroundColor:"white"}}>
			<div className="circle_container_big">
					<div className="circle">
						<Image
							className="circle_Image"
							publicId={imgSrc}
							cloudName={"Champiz"}
						/>
					</div>
				</div>
				<p className="popup_name">{name}</p>
				{rankImg&&isNaN(rankImg)&&<img 
				style={rankImg=="Platinum"?{width:'40px'}:{width:'50px'}}
				src={require(`../images/ranking/${rankImg}.svg`).default} alt="" />}
			</div>
			<div className="popup_content_container">
				{content}
			</div>
		</div>
	)
}

export default PopUp
