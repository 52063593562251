import React, { useEffect, useState } from "react";
import axios from "axios";
import { socket } from "../socketProvider";
export const DataContext = React.createContext();

const DataContextProvider = (props) => {
	const [componentsSearchCondition, setComponentsSearchCondition] = useState(
		(prev) => (element) => element
	);
	const [chatSearchCondition, setChatSearchCondition] = useState(undefined);
	const [currentUserData, setCurrentUserData] = useState({});
	const [currentChat, setCurrentChat] = useState(null);
	const [LoadingToUpload, setLoadingToUpload] = useState(false);
	const [imgData, setImgData] = useState("");
	const [imgFile, setImgFile] = useState(null);
	const [progress, setProgress] = useState(10);
	const [posts, setPosts] = useState([]);
	const [followingUsers, setFollowingUsers] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [userPosts, setUserPosts] = useState([]);
	const [users, setUsers] = useState([]);
	const [showPopUp, setShowPopUp] = useState(false);
	const [room, setRoom] = useState("");
	const [messages, setMessages] = useState([]);
	const [conversationPartner, setConversationPartner] = useState(null);
	const [LevelUpRank, setLevelUpRank] = useState(null);
	const [limitRank, setLimitRank] = useState(null);
	const [chats, setChats] = useState([]);
	const [onlineUsers, setOnlineUsers] = useState([]);

	//check if should render the main page or other
	const [ChosenPage, setChosenPage] = useState(
		window.location.pathname.slice(1) == ""
			? "feed"
			: window.location.pathname.slice(1)
	);
	function translateRank(rank) {
		switch (rank) {
			case 1:
				return "Bronze";
			case 2:
				return "Silver";
			case 3:
				return "Gold";
			case 4:
				return "Platinum";
			case 5:
				return "Diamond";
			case "Bronze":
				return 1;
			case "Silver":
				return 2;
			case "Gold":
				return 3;
			case "Platinum":
				return 4;
			case "Diamond":
				return 5;
			default:
				return rank;
		}
	}
	function deleteUser(user) {
		if (window.confirm("Are you sure you want to delete this user?")) {
			axios
				.delete(`https://champiz-server.herokuapp.com/users/delete/${user.id}`, {
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				})
				.then((res) => {
					axios
						.get("https://champiz-server.herokuapp.com/users/getAllUsers", {
							headers: {
								"access-token":
									localStorage.getItem("access-token"),
							},
						})
						.then((res) => setUsers(res.data));
				});
		}
	}
	function deleteComment(commentId) {
		if (window.confirm("Are you sure you want to delete this comment?")) {
			return axios.delete(
				`https://champiz-server.herokuapp.com/comments/delete/${commentId}`,
				{
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				}
			);
		}
	}
	function deleteChat(chatId) {
		if (window.confirm("Are you sure you want to delete this chat?")) {
			return axios
				.delete(`https://champiz-server.herokuapp.com/chat/delete/${chatId}`, {
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				})
				.then((res) => {
					axios
						.get("https://champiz-server.herokuapp.com/chat/getChats", {
							headers: {
								id: currentUserData.id,
								"access-token":
									localStorage.getItem("access-token"),
							},
						})
						.then((res) => {
							
							setChats(res.data);
						});
				});
		}
	}
	function deleteRelationship(sourceId, targetId) {
		return axios
			.delete(
				`https://champiz-server.herokuapp.com/relationships/deleteRelationship/${sourceId}/${targetId}`,
				{
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				}
			)
			.then((res) => {
				getFollowingUsers(sourceId);
				alert(`you been unfollowed this user`);
			});
	}
	function checkStringLength(num, content) {
		let words = content.split(" ");
		for (let i in words) {
			if (words[i].length > num) words[i] = words[i].substring(0, num);
		}
		return words.join().replaceAll(",", " ");
	}
	function getAllPosts() {
		return axios.get("https://champiz-server.herokuapp.com/posts/getAllPosts", {
			headers: {
				"access-token": localStorage.getItem("access-token"),
			},
		});
	}
	function uploadProgress() {
		const timer = setInterval(() => {
			if (progress >= 100) {
				setProgress(0);
				clearInterval(timer);
			}
			setProgress((prevProgress) =>
				prevProgress >= 100 ? 100 : prevProgress + 10
			);
		}, 300);
	}
	function handleImgChange(event) {
		setImgData("reader.result");
		const file = event.target.files[0];
		if (file && file["type"].includes("gif")) return;
		setImgFile(file);
		let reader = new FileReader();
		try {
			reader.readAsDataURL(file);
		} catch (err) {
			console.log(err);
		}
		reader.onload = function () {
			setImgData(reader.result);
		};
	}
	function uploadImage() {
		uploadProgress();
		setLoadingToUpload(true);
		const formData = new FormData();
		formData.append("file", imgFile);
		formData.append("tags", "display");
		formData.append("upload_preset", "yph4txmh");

		return axios
			.post(
				`https://api.cloudinary.com/v1_1/champiz/image/upload`,
				formData
			)

			.catch((err) => alert(err))
			.then((res) => {
				setLoadingToUpload(false);
				return res;
			});
	}
	function getCurrentUserID() {
		return axios.get("https://champiz-server.herokuapp.com/users/getIdByEmail", {
			headers: {
				email: localStorage.getItem("email"),
				"access-token": localStorage.getItem("access-token"),
			},
		});
	}
	function checkUserRankAndUpdate(user,limitUpRank,limitDownRank){
		console.log(user.xp)
		console.log(limitUpRank)
		if(user.xp>=limitUpRank?.xpLimit)
		return axios.put(
			"https://champiz-server.herokuapp.com/users/updateRank",
			{id:user.id,action:'LevelUp' },
			{
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			}
		).then(res=>setCurrentUserData(prev=>{return {...prev,userRank:translateRank(prev.userRank)+1}}))
		if(user.xp<=limitDownRank?.xpLimit)
		return axios.put(
			"https://champiz-server.herokuapp.com/users/updateRank",
			{id:user.id,action:'LevelDown' },
			{
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			}
		).then(res=>setCurrentUserData(prev=>{return {...prev,userRank:translateRank(prev.userRank)-1}}))
		
	}
	function ActiveIconNavBar(e) {
		setTimeout(() => {
			let url = window.location.pathname.slice(1);
			setChosenPage(url);
		}, 100);
	}
	function handlePostTime(postDate) {
		let postTimePerLevel = 10
		switch(currentUserData.userRank){
			case "Bronze":postTimePerLevel=5
				break;
			case "Silver":postTimePerLevel=7
				break;
			case "Gold":postTimePerLevel=9
				break;
			case "Platinum":postTimePerLevel=12
				break;
			case "Diamond":postTimePerLevel=24
		}
		let hours = msToTime(Math.abs(new Date() - new Date(postDate)));
		return postTimePerLevel - hours;
	}
	function msToTime(duration) {
		var milliseconds = Math.floor((duration % 1000) / 100),
			seconds = Math.floor((duration / 1000) % 60),
			minutes = Math.floor((duration / (1000 * 60)) % 60),
			hours = Math.floor(duration / (1000 * 60 * 60));

		hours = hours < 10 ? "0" + hours : hours;
		minutes = minutes < 10 ? "0" + minutes : minutes;
		seconds = seconds < 10 ? "0" + seconds : seconds;

		return hours;
	}
	
	function joinRoom(currentUserId, userId) {
		//we want always the same room id so we concate the users id togather
		socket.emit(
			"join_room",
			currentUserId > userId
				? userId + "" + currentUserId
				: currentUserId + "" + userId
		);
	}
	function createConversation(name, rank, imgSrc, userId) {
		setRoom(
			currentUserData.id > userId
				? userId + "" + currentUserData.id
				: currentUserData.id + "" + userId
		);
		setConversationPartner({
			name: name,
			rank: translateRank(rank),
			imgSrc: imgSrc,
			id: userId,
		});
		setCurrentChat();
		openChatPopUp();
		// if (currentUserData) {

		// 		joinRoom(userId);
		// }
	}
	function fetchUserPosts(id) {
		return axios
			.get("https://champiz-server.herokuapp.com/posts/getUserPosts", {
				headers: {
					id: id,
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => setUserPosts(res.data));
	}
	function getCurrentUserData(id) {
		return axios.get("https://champiz-server.herokuapp.com/users/getCurrentUserData", {
			headers: {
				id: id,
				"access-token": localStorage.getItem("access-token"),
			},
		});
	}
	function getLimitRank(id) {
		return axios
			.get("https://champiz-server.herokuapp.com/rank/getLimitRank", {
				headers: {
					id: id,
					"access-token": localStorage.getItem("access-token"),
				},
			})
			
	}

	function getNotifications(id) {
		axios
			.get("https://champiz-server.herokuapp.com/notfications/getAll", {
				headers: {
					id: id,
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => {
				let tempNotifications = res.data;
				tempNotifications = tempNotifications
					.map((not) => {
						return {
							...not,
							date: new Date(not.date).toString(),
							body: not.body + " " + not.sourceName,
							link:
								not.body.includes("cheer") ||
								not.body.includes("comment")
									? `feed/${not.eventId}`
									: `profile${not.eventId}`,
						};
					})
					.reverse();
				setNotifications(tempNotifications);
			});
	}
	function newRelation(targetUser) {
		axios
			.post(
				"https://champiz-server.herokuapp.com/relationships/newRelationship",
				{
					sourceId: currentUserData.id,
					targetId: targetUser.id,
				},
				{
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				}
			)
			.then((res) => {
				setFollowingUsers((prev) => [...prev, targetUser]);
				alert(`you followed ${targetUser.username}`);
				axios
					.get("https://champiz-server.herokuapp.com/posts/followingPosts", {
						headers: {
							id: currentUserData.id,
							"access-token":
								localStorage.getItem("access-token"),
						},
					})
					.then((res) => setPosts(res.data))
					.catch((err) => console.log(err));
			});
	}
	function openChatPopUp() {
		setShowPopUp(true);
	}
	function getPopularPosts(id) {
		return axios.get("https://champiz-server.herokuapp.com/posts/getPopularPosts", {
			headers: {
				id: id,
				"access-token": localStorage.getItem("access-token"),
			},
		});
	}
	function closeChatPopUp() {
		setShowPopUp(false);
	}
	function getFollowingUsers(id) {
		axios
			.get("https://champiz-server.herokuapp.com/users/followingUsers", {
				headers: {
					sourceId: id,
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => setFollowingUsers(res.data))
			.catch((err) => console.log(err));
	}

	useEffect(() => {
		if (localStorage.getItem("access-token")) {
			getCurrentUserID().then((res) => {
				const userId = res.data;
				getCurrentUserData(res.data)
					.then((res) => {
							getLimitRank(res.data[0].userRank + 1)
							.then((limitResult) => {
								setLimitRank(limitResult.data[0].xpLimit)
								checkUserRankAndUpdate(res.data[0],limitResult.data[0])
							});
							getLimitRank(res.data[0].userRank)
							.then((prevLimitResult) => {
								checkUserRankAndUpdate(res.data[0],null,prevLimitResult.data[0])
							});
						//keep the numeric level
						setLevelUpRank(translateRank(res.data[0].userRank + 1));
						res.data[0].userRank = translateRank(
							res.data[0].userRank
						);
						setCurrentUserData(res.data[0]);
						
					})
					.catch((err) => console.log(err));

				axios
					.get("https://champiz-server.herokuapp.com/posts/followingPosts", {
						headers: {
							id: userId,
							"access-token":
								localStorage.getItem("access-token"),
						},
					})
					.then((res) => setPosts(res.data))
					.catch((err) => console.log(err));

				getFollowingUsers(userId);

				fetchUserPosts(userId);

				getNotifications(userId);

				socket.on("users", (data) => {
					console.log(data)
					setOnlineUsers(data);
				});

				socket.emit("userConnection",userId)
				

				axios
					.get("https://champiz-server.herokuapp.com/chat/getChats", {
						headers: {
							id: userId,
							"access-token":
								localStorage.getItem("access-token"),
						},
					})
					.then((res) => {
						res.data.forEach((chat) => {
							let otherUserId =
								chat.user1Id == userId
									? chat.user2Id
									: chat.user1Id;
							joinRoom(userId, otherUserId);
						});
						setChats(res.data);
					});
			});
			axios
				.get("https://champiz-server.herokuapp.com/users/getAllUsers", {
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				})
				.then((res) => {
					let info = [];
					if (res.data instanceof Array) {
						info = res.data.map((user) => {
							return {
								...user,
								userRank: translateRank(user.userRank),
							};
						});
						setUsers(info);
					}
				});
		}
		axios
			.get("https://champiz-server.herokuapp.com/chat/getAllMessages", {
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => {
				setMessages(res.data);
			});
	}, []);
	return (
		<DataContext.Provider
			value={{
				users: users,
				setUsers: setUsers,
				posts: posts,
				currentUserData: currentUserData,
				componentsSearchCondition: componentsSearchCondition,
				setComponentsSearchCondition: setComponentsSearchCondition,
				chatSearchCondition: chatSearchCondition,
				setChatSearchCondition: setChatSearchCondition,
				translateRank: translateRank,
				setChosenPage: setChosenPage,
				ChosenPage: ChosenPage,
				notifications: notifications,
				getCurrentUserID: getCurrentUserID,
				uploadImage: uploadImage,
				LoadingToUpload: LoadingToUpload,
				handleImgChange: handleImgChange,
				setCurrentUserData: setCurrentUserData,
				imgData: imgData,
				progress: progress,
				checkStringLength: checkStringLength,
				userPosts: userPosts,
				setUserPosts: setUserPosts,
				fetchUserPosts: fetchUserPosts,
				openChatPopUp: openChatPopUp,
				closeChatPopUp: closeChatPopUp,
				conversationPartner: conversationPartner,
				setConversationPartner: setConversationPartner,
				showPopUp: showPopUp,
				setShowPopUp: setShowPopUp,
				socket: socket,
				room: room,
				setRoom: setRoom,
				createConversation: createConversation,
				messages: messages,
				setMessages: setMessages,
				ActiveIconNavBar: ActiveIconNavBar,
				followingUsers: followingUsers,
				setFollowingUsers: setFollowingUsers,
				getPopularPosts: getPopularPosts,
				newRelation: newRelation,
				LevelUpRank: LevelUpRank,
				handlePostTime: handlePostTime,
				msToTime: msToTime,
				limitRank: limitRank,
				chats: chats,
				setChats: setChats,
				currentChat: currentChat,
				setCurrentChat: setCurrentChat,
				getAllPosts: getAllPosts,
				setPosts: setPosts,
				deleteRelationship: deleteRelationship,
				setNotifications: setNotifications,
				deleteUser: deleteUser,
				deleteComment: deleteComment,
				deleteChat: deleteChat,
				onlineUsers:onlineUsers
			}}>
			{props.children}
		</DataContext.Provider>
	);
};

export default DataContextProvider;
