import React,{useEffect, useState} from "react";
import Man from '../../images/ranking/Man.svg'
import Woman from '../../images/ranking/Woman.svg'
function RankingBar({setChosenRank,setGenderFilter,currentUserGender}) {
	const RANKS = ["Diamond","Platinum","Gold","Silver","Bronze"]
	const [gender,SetGender] = useState(currentUserGender) 
	useEffect(()=>{
		SetGender(currentUserGender)
	},[currentUserGender])
	useEffect(()=>{
		if(gender=='Male')
		setGenderFilter(prev=>user=>user.gender=='Male')
		else if(gender=='Female')
		setGenderFilter(prev=>user=>user.gender=='Female')
	},[gender])
	return (
		<div className="ranking_bar_container">
			<div className="avatars_container">
				<img src={Man} style={gender=='Male'?{border:'5px solid white'}:{border:'0px'}} onClick={()=>{setGenderFilter(prev=>user=>user.gender=='Male');SetGender('Male')}} alt="man" className="avatar" />
				<img src={Woman} style={gender=='Female'?{border:'5px solid white'}:{border:'0px'}} onClick={()=>{setGenderFilter(prev=>user=>user.gender=='Female');SetGender('Female')}} alt="woman" className="avatar" />
			</div>
			<div className="ranking_bar_container_levels">
			{RANKS.map(rank=><img className="rank_bar_img" id={rank} onClick={()=>setChosenRank(rank)} src={require(`../../images/ranking/${rank}Rank.svg`).default}/>)}
			</div>
		</div>
	);
}

export default RankingBar;
