import { Image } from 'cloudinary-react'
import React from 'react'

function Message({content,profileImg,who,direction,partnerProfileImg,currentUserData}) {
	return (
		<div >

				<div style={{display:'flex',flexDirection:who==currentUserData.id?'row':'row-reverse'}} className={who==currentUserData.id?"chat_message_container_me":"chat_message_container_partner"}>
					<div className="circle_container_small msg_pic">
					<div className="circle">
						<Image
							className="circle_Image"
							publicId={who==currentUserData.id?profileImg:partnerProfileImg}
							cloudName={"Champiz"}
						/>
					</div>
				</div>
					<p style={{direction:direction}} className="chat_message_content">{content}</p>
				</div>
			
		</div>
	)
}

export default Message
