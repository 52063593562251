import React, { useEffect, useState, useContext } from "react";
import "../../styles/Chat.css";
import ChatHeader from "./ChatHeader";
import { DataContext } from "../DataContext";
import Conversation from "./Conversation";



function Chat({ openChatPopUp, setConversationPartner,setMessages ,messages,socket,room,setRoom,onlineUsers}) {
	const { users,currentUserData, chatSearchCondition,followingUsers,chats,conversationPartner } = useContext(DataContext);
	useEffect(() => {
	}, [users]);

	useEffect(() => {
		console.log(onlineUsers) 
	}, [onlineUsers]);
	return (
		<div className="chat_container">
			<ChatHeader />
			{chats.length > 0 &&
				chats
				.map(chat=>{
					
					return {...chat,
						username:users.find(user=>{
						return user.id != currentUserData.id && user.id == chat.user1Id || user.id == chat.user2Id
						}).username,
					messages:messages
					.filter(msg=>msg.chatId == chat.id 
						||isNaN(msg.chatId) && msg.chatId.replace("&","").replace(currentUserData.id+'','') == chat.user1Id 
						||isNaN(msg.chatId) && msg.chatId.replace("&","").replace(currentUserData.id+'','') == chat.user2Id)
					}
					
				})
				.sort
				(
					(chatA,chatB)=>
					{
						if(chatA.messages.length<=0)
						return -1
						else if(chatB.messages.length<=0)
						return 1
						
						
						return  new Date(
							new Date(chatA.messages[chatA.messages.length-1]?.messageCreation).getTime()
							-new Date(chatB.messages[chatB.messages.length-1]?.messageCreation).getTime()
						).getTime()
					}
				)
				.reverse()
					.filter(
						chatSearchCondition 
							? chatSearchCondition
							: (element) => element
					)
					.map((chat) => {
						
						let user = followingUsers.find(usr => usr.id != currentUserData.id && (usr.id == chat.user1Id || usr.id == chat.user2Id))
						if(!user)
						user = users.find(usr => usr.id != currentUserData.id && (usr.id == chat.user1Id || usr.id == chat.user2Id))
						return(
						<Conversation
						room={room}
						setRoom={setRoom}
						socket={socket}
						messages={chat.messages}
						setMessages={setMessages}
						openChatPopUp={openChatPopUp}
						rank={user.userRank}
						setConversationPartner={setConversationPartner}
						name={user.username}
						imgSrc={user.profileImage}
						chatNotifications={5}
						status={onlineUsers.find(userFromServer=>userFromServer.id==user.id)?'online':'offline'}
						userId={user.id}
						currentUserData={currentUserData}
						chatId={chat.id}
						/>
						)}
					)
				}
		</div>
	);
}

export default Chat;
