import React,{useContext} from 'react'
import {BiSearchAlt as SearchIcon} from 'react-icons/bi'
import { DataContext } from './DataContext'
import '../styles/Search.css'
function Search({percent,location}) {
	const {setComponentsSearchCondition,setChatSearchCondition,users} = useContext(DataContext)
	function handleSearch(e){
		let searchIn = e.target.value
		if(searchIn.length>0){
			if(location=='feed')
			setComponentsSearchCondition(
				prev=>
				p=>
				users.find(user=>user.id == p.postUserId)
				.username?.toUpperCase()
				.startsWith(searchIn.toUpperCase()))
				if(location=='users')
				setComponentsSearchCondition(
					prev=>
					p=>
					p.username?.toUpperCase()
				.startsWith(searchIn.toUpperCase()))
			if(location == 'ranking')
			setComponentsSearchCondition(
				prev=>
				p=>
				p.username?.toUpperCase()
				.startsWith(searchIn.toUpperCase()))
			if(location=='chat')
			setChatSearchCondition(prev=>e=>e.username.toUpperCase().startsWith(searchIn.toUpperCase()))
		}else{
			if(location=='feed'||location=='ranking'||location=='users')
			setComponentsSearchCondition(prev=>e=>e)
			if(location=='chat')
			setChatSearchCondition(prev=>e=>e)
		}
	}
	return (
		<div style={{width:percent}} className="search_container">
			<input onChange={handleSearch} className="search_input" placeholder="Search..." type="text"/>
			<SearchIcon className="search_icon" size={40}/>
		</div>
	)
}

export default Search
