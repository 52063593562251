import React, { useEffect, useContext, useState } from "react";
import Description from "./Description";
import ProfileImage from "./ProfileImage";
import MyGallery from "./MyGallery";
import { DataContext } from "../DataContext";
import "../../styles/Profile.scss";
export default function Profile() {
	const { currentUserData, users,translateRank } = useContext(DataContext);
	const [userIdFromLink, setUserIdFromLink] = useState(
		window.location.pathname.slice(8)
	);
	const [userToPresent, setUserToPresent] = useState(undefined);
	function checkProfileUser(id) {
		return currentUserData.id == id;
	}
	useEffect(() => {
		window.onscroll = null;
		let tempUser = users.find((user) => user.id == userIdFromLink);
		if (tempUser) setUserToPresent(tempUser);
		else setUserToPresent(currentUserData);
	}, [users, currentUserData]);
	useEffect(() => {
		if (userToPresent) {
		}
	}, [userToPresent]);
	if (userToPresent && users.length > 0) {
		return (
			<div className="profile_container">
				<img
					className="rank_profile_img"
					src={
						require(`../../images/ranking/${
							userToPresent.userRank&&isNaN(userToPresent.userRank)
								? userToPresent.userRank
								: "Bronze"
						}.svg`).default
					}
					alt="Level"
				/>
				<h4 className="profile_title_name">{userToPresent.username}</h4>
				<div className="image_description_container">
					<Description
						userId={userToPresent.id}
						checkProfileUser={checkProfileUser}
						content={userToPresent.profileDescription}
					/>
					<ProfileImage
						checkProfileUser={checkProfileUser}
						name={userToPresent.username}
						userId={userToPresent.id}
						user={userToPresent}
						profileImage={userToPresent.profileImage}
						followers={userToPresent.followers}
					/>
				</div>
				<div
					style={{
						marginTop: "100px",
						padding: "20px",
						borderTop: "1px solid lightgray",
					}}>
					<MyGallery userId={userToPresent.id} />
				</div>
			</div>
		);
	} else return "Loading";
}
