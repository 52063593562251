import React, { useState,useEffect, useContext } from "react";
import Search from "../Search";
import PopUp from "../PopUp";
import { Button } from "@material-ui/core";

import axios from "axios";
import LoadingSpinner from '../LoadingSpinner'
import { DataContext } from "../DataContext";
function FeedHeader({uploadImage,LoadingToUpload,handleImgChange,imgData,progress }) {
	const [showPostPopUp, setShowPostPopUp] = useState(false);
	const [postDescription, setPostDescription] = useState("");
	const {currentUserData,setPosts,posts} = useContext(DataContext)

	function checkPostPremission(){
		switch(currentUserData.userRank){
			case "Bronze":return posts.filter(post=>post.postUserId==currentUserData.id&&
				new Date().toDateString() == new Date(post.publishDate).toDateString()).length < 3;
			case "Silver":return posts.filter(post=>post.postUserId==currentUserData.id&&
				new Date().toDateString() == new Date(post.publishDate).toDateString()).length < 6
			case "Gold":return posts.filter(post=>post.postUserId==currentUserData.id&&
				new Date().toDateString() == new Date(post.publishDate).toDateString()).length < 9
			case "Platinum":return posts.filter(post=>post.postUserId==currentUserData.id&&
				new Date().toDateString() == new Date(post.publishDate).toDateString()).length < 12
			case "Diamond":return posts.filter(post=>post.postUserId==currentUserData.id&&
				new Date().toDateString() == new Date(post.publishDate).toDateString()).length < 15
		}
	}
	function handlePostPopUpOpen() {
		setShowPostPopUp(true);
	}
	function handlePostPopUpClose() {
		setShowPostPopUp(false);
	}
	

	function uploadPost() {
		uploadImage()
	
			.then((res) => {
				const result = res.data.public_id;
				setPostDescription("")
				handlePostPopUpClose();
			
					axios.post(
						"https://champiz-server.herokuapp.com/posts/newPost",
						{
							description: postDescription,
							imageSource: result,
							postUserId: currentUserData.id,
						},
						{
							headers: {
								"access-token":
									localStorage.getItem("access-token"),
							},
						}
					).then(res=>{
						axios
					.get("https://champiz-server.herokuapp.com/posts/followingPosts", {
						headers: {
							id:currentUserData.id,
							"access-token":
								localStorage.getItem("access-token"),
						},
					})
					.then((res) => setPosts(res.data))
					.catch((err) => console.log(err));
					})
				
				
			});
	}


useEffect(()=>{
},[imgData])
	return (
		<div className="FeedHeader_container">
			<div className="FeedHeader_search_container">
				<Search location={'feed'}/>
			</div>
			{/* Activate the file input click event */}
			<button
				onClick={checkPostPremission()?handlePostPopUpOpen:()=>alert("you out of posts for today will reset tomarrow!")}
				className="FeedHeader_btn">
				Create post
			</button>

			<input
				onChange={handleImgChange}
				style={{ display: "none" }}
				id="post_input"
				type="file"
				accept="image/*"
			/>
			{/* Your details to the popup */}
			<PopUp
				imgSrc={
					currentUserData.profileImage
				}
				rankImg={currentUserData.userRank}
				content={
					<div className="post_img_input">
						<div className="post_img_input_holder">
							{
								<h5
									style={{
										position: "absolute",
										zIndex: 333,
									}}
									onClick={() =>
										document
											.getElementById("post_input")
											.click()
									}>
									edit ✎
								</h5>
							}
							<img src={imgData} alt="" />
						</div>
						<textarea
							cols="60"
							rows="4"
							value={postDescription}
							onChange={(e)=>setPostDescription(e.target.value)}
							placeholder="Write your description here..."></textarea>
						{!LoadingToUpload && (
							<Button
								onClick={uploadPost}
								className="post_img_input_btn"
								variant="contained"
								color="primary">
								Submit
							</Button>
						)}
						{LoadingToUpload && (
							<LoadingSpinner
								className="post_img_input_btn"
								value={progress}
							/>
						)}
					</div>
				}
				name={currentUserData.username}
				showPopUp={showPostPopUp}
			/>
			<div
				onClick={LoadingToUpload == false ? handlePostPopUpClose : null}
				className="blur_background"
				style={
					showPostPopUp ? { display: "flex" } : { display: "none" }
				}></div>
		</div>
	);
}


export default FeedHeader;
