import React from "react";
import Search from "../Search";
import ProgressBar from "../ProgressBar";

function RankingHeader({ rank,myRank,LevelUpRank,xp,limitRank }) {
	return (
		<>
			<div className="ranking_header_container">
				{rank && (
					<img
						className="ranking_rank_img"
						src={
							require(`../../images/ranking/${rank}.svg`).default
						}
						alt=""
					/>
				)}
				<div className="ranking_search_container">
					<Search location={"ranking"} />
					<div className="xp_container">
						{isNaN(LevelUpRank)?
						<p>{xp}/{limitRank} ➤ {myRank&&<img src={
							require(`../../images/ranking/${LevelUpRank}.svg`).default
						}></img>}</p>
						:<p style={{fontFamily:'input_font'}}>Already at the maximum level</p>}
						<ProgressBar completed={isNaN(LevelUpRank)?Number.parseFloat(xp*100/limitRank).toFixed(2):100} bgcolor={`var(--blue)`} />
					</div>
				</div>
			</div>
		</>
	);
}

export default RankingHeader;
