import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
function Reports() {
	const [chartData, setChartData] = useState(null);
	const [selectedQuery, setSelectedQuery] = useState(prev=>fetchOnlineByDate);
	function isDateInThisWeek(date) {
		const todayObj = new Date();
		const todayDate = todayObj.getDate();
		const todayDay = todayObj.getDay();

		// get first date of week
		const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

		// get last date of week
		const lastDayOfWeek = new Date(firstDayOfWeek);
		lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

		// if date is equal or within the first and last dates of the week
		return date >= firstDayOfWeek && date <= lastDayOfWeek;
	}

	function usersAge() {
		let dataArr = [];
		let labelsArr = [];
		axios
			.get("https://champiz-server.herokuapp.com/users/getAllUsers", {
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => {
				let labelsArr = [
					...new Set(res.data.map((user) =>user.age)),
				];
				for (const age of labelsArr) {
					dataArr.push(
						res.data.filter((user) => user.age == age).length
					);
				}
				setChartData({
					labels: labelsArr.sort().map(label=>'age '+label),
					datasets: [
						{
							label: "users age",
							data: dataArr,
							backgroundColor: ["rgba(75, 192, 192, 0.6)"],
							borderWidth: 4,
						},
					],
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	function fetchOnlineByDate(type) {
		let dataArr = [];
		let labelsArr = [];
		axios
			.get("https://champiz-server.herokuapp.com/admin/online", {
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => {
				switch (type) {
					case "daily":
						{
							let dataFromCurrentDay = res.data.filter(
								(online) =>
									new Date().toDateString() ==
									new Date(online.onlineDate).toDateString()
							);
							let unicObj = {};
							for (
								let i = 0;
								i < dataFromCurrentDay.length;
								i++
							) {
								let index = new Date(
									dataFromCurrentDay[i].onlineDate
								).getHours();
								if (unicObj[index]) {
									unicObj[index] += parseInt(
										dataFromCurrentDay[i].quantity
									);
								} else
									unicObj[index] = parseInt(
										dataFromCurrentDay[i].quantity
									);
							}
							dataArr = [...Object.values(unicObj)];
							labelsArr = [
								...Object.keys(unicObj)
								.sort(function(a, b){if(b==0) return -1 ;if(a==0)return 1;return a - b})
								.map(
									(label) => "Hour:" + label + ":00"
								),
							];
						}
						break;
					case "weekly":
						{
							var days = [
								"ASunday",
								"BMonday",
								"CTuesday",
								"DWednesday",
								"EThursday",
								"FFriday",
								"GSaturday",
							];
							let dataFromCurrentWeek = res.data.filter(
								(online) =>
									isDateInThisWeek(
										new Date(online.onlineDate)
									)
							);
							let unicObj = {};
							for (
								let i = 0;
								i < dataFromCurrentWeek.length;
								i++
							) {
								let index =
									days[
										new Date(
											dataFromCurrentWeek[i].onlineDate
										).getDay()
									];
								if (unicObj[index]) {
									unicObj[index] += parseInt(
										dataFromCurrentWeek[i].quantity
									);
								} else
									unicObj[index] = parseInt(
										dataFromCurrentWeek[i].quantity
									);
							}
							dataArr = [...Object.values(unicObj)];
							labelsArr = [...Object.keys(unicObj)]
								.sort()
								.map((label) => label.slice(1, label.length));
						}
						break;
					case "monthly": {
						const monthNames = [
							"January",
							"February",
							"March",
							"April",
							"May",
							"June",
							"July",
							"August",
							"September",
							"October",
							"November",
							"December",
						];
						let dataFromCurrentMonth = res.data.filter(
							(online) =>
								new Date(online.onlineDate).getFullYear() ==
								new Date().getFullYear()
						);
						let unicObj = {};
						for (let i = 0; i < dataFromCurrentMonth.length; i++) {
							let index = new Date(
								dataFromCurrentMonth[i].onlineDate
							).getMonth();
							if (unicObj[index]) {
								unicObj[index] += parseInt(
									dataFromCurrentMonth[i].quantity
								);
							} else
								unicObj[index] = parseInt(
									dataFromCurrentMonth[i].quantity
								);
						}
						dataArr = [...Object.values(unicObj)];
						labelsArr = [...Object.keys(unicObj)]
							.sort(function(a, b){return a - b})
							.map((monthNum) => monthNames[monthNum]);
					}
				}
				setChartData({
					labels: labelsArr,
					datasets: [
						{
							label: "online by date",
							data: dataArr,
							backgroundColor: ["rgba(75, 192, 192, 0.6)"],
							borderWidth: 4,
						},
					],
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	function fetchRegistrationByDate(type) {
		let dataArr = [];
		let labelsArr = [];
		axios
			.get("https://champiz-server.herokuapp.com/users/getAllUsers", {
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => {
				switch (type) {
					case "daily":
						{
							let dataFromCurrentDay = res.data.filter(
								(user) =>
									new Date().toDateString() ==
									new Date(user.registrationDate).toDateString()
							);
							let unicObj = {};
							for (
								let i = 0;
								i < dataFromCurrentDay.length;
								i++
							) {
								let index = new Date(
									dataFromCurrentDay[i].registrationDate
								).getHours();
								if (unicObj[index]) {
									unicObj[index] += 1
								} else
									unicObj[index] = 1
							}
							dataArr = [...Object.values(unicObj)];
							labelsArr = [
								...Object.keys(unicObj)
								.sort(function(a, b){if(b==0) return -1 ;if(a==0)return 1;return a - b})
								.map(
									(label) => "Hour:" + label + ":00"
								),
							];
						}
						break;
					case "weekly":
						{
							var days = [
								"ASunday",
								"BMonday",
								"CTuesday",
								"DWednesday",
								"EThursday",
								"FFriday",
								"GSaturday",
							];
							let dataFromCurrentWeek = res.data.filter(
								(user) =>
									isDateInThisWeek(
										new Date(user.registrationDate)
									)
							);
							let unicObj = {};
							for (
								let i = 0;
								i < dataFromCurrentWeek.length;
								i++
							) {
								let index =
									days[
										new Date(
											dataFromCurrentWeek[i].registrationDate
										).getDay()
									];
								if (unicObj[index]) {
									unicObj[index] += 1
								} else
									unicObj[index] = 1
							}
							dataArr = [...Object.values(unicObj)];
							labelsArr = [...Object.keys(unicObj)]
								.sort()
								.map((label) => label.slice(1, label.length));
						}
						break;
					case "monthly": {
						const monthNames = [
							"January",
							"February",
							"March",
							"April",
							"May",
							"June",
							"July",
							"August",
							"September",
							"October",
							"November",
							"December",
						];
						let dataFromCurrentMonth = res.data.filter(
							(user) =>
								new Date(user.registrationDate).getFullYear() ==
								new Date().getFullYear()
						);
						let unicObj = {};
						for (let i = 0; i < dataFromCurrentMonth.length; i++) {
							let index = new Date(
								dataFromCurrentMonth[i].registrationDate
							).getMonth();
							if (unicObj[index]) {
								unicObj[index] += 1
							} else
								unicObj[index] = 1
						}
						dataArr = [...Object.values(unicObj)];
						labelsArr = [...Object.keys(unicObj)]
							.sort(function(a, b){return a - b})
							.map((monthNum) => monthNames[monthNum]);
					}
				}
				setChartData({
					labels: labelsArr,
					datasets: [
						{
							label: "registration by date",
							data: dataArr,
							backgroundColor: ["rgba(75, 192, 192, 0.6)"],
							borderWidth: 4,
						},
					],
				});
			})
			.catch((err) => {
				console.log(err);
			});
			
	}
	function fetchPostsByDate(type) {
		let dataArr = [];
		let labelsArr = [];
		axios
			.get("https://champiz-server.herokuapp.com/posts/getAllPosts", {
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			})
			.then((res) => {
				switch (type) {
					case "daily":
						{
							let dataFromCurrentDay = res.data.filter(
								(post) =>
									new Date().toDateString() ==
									new Date(post.publishDate).toDateString()
							);
							let unicObj = {};
							for (
								let i = 0;
								i < dataFromCurrentDay.length;
								i++
							) {
								let index = new Date(
									dataFromCurrentDay[i].publishDate
								).getHours();
								if (unicObj[index]) {
									unicObj[index] += 1
								} else
									unicObj[index] = 1
							}
							dataArr = [...Object.values(unicObj)];
							labelsArr = [
								...Object.keys(unicObj)
								.sort(function(a, b){if(b==0) return -1 ;if(a==0)return 1;return a - b})
								.map(
									(label) => "Hour:" + label + ":00"
								),
							];
						}
						break;
					case "weekly":
						{
							var days = [
								"ASunday",
								"BMonday",
								"CTuesday",
								"DWednesday",
								"EThursday",
								"FFriday",
								"GSaturday",
							];
							let dataFromCurrentWeek = res.data.filter(
								(post) =>
									isDateInThisWeek(
										new Date(post.publishDate)
									)
							);

							let unicObj = {};
							for (
								let i = 0;
								i < dataFromCurrentWeek.length;
								i++
							) {
								let index =
									days[
										new Date(
											dataFromCurrentWeek[i].publishDate
										).getDay()
									];
								if (unicObj[index]) {
									unicObj[index] += 1
								} else
									unicObj[index] = 1
							}
							dataArr = [...Object.values(unicObj)];
							labelsArr = [...Object.keys(unicObj)]
								.sort()
								.map((label) => label.slice(1, label.length));
						}
						break;
					case "monthly": {
						const monthNames = [
							"January",
							"February",
							"March",
							"April",
							"May",
							"June",
							"July",
							"August",
							"September",
							"October",
							"November",
							"December",
						];
						let dataFromCurrentMonth = res.data.filter(
							(post) =>
								new Date(post.publishDate).getFullYear() ==
								new Date().getFullYear()
						);
						let unicObj = {};
						for (let i = 0; i < dataFromCurrentMonth.length; i++) {
							let index = new Date(
								dataFromCurrentMonth[i].publishDate
							).getMonth();
							if (unicObj[index]) {
								unicObj[index] += 1
							} else
								unicObj[index] = 1
						}
						dataArr = [...Object.values(unicObj)];
						labelsArr = [...Object.keys(unicObj)]
							.sort(function(a, b){return a - b})
							.map((monthNum) => monthNames[monthNum]);
					}
				}
				setChartData({
					labels: labelsArr,
					datasets: [
						{
							label: "posts by date",
							data: dataArr,
							backgroundColor: ["rgba(75, 192, 192, 0.6)"],
							borderWidth: 4,
						},
					],
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	function selectFunction(value){
		switch(value){
			case "online users query":setSelectedQuery((prev)=>fetchOnlineByDate)
				break;
			case "registration users query":setSelectedQuery((prev)=>fetchRegistrationByDate)
				break;
			case "user's age query":setSelectedQuery((prev)=>usersAge)
				break;
			case "user's posts query":setSelectedQuery((prev)=>fetchPostsByDate)
		
		}

	}


	useEffect(() => {
		console.log(chartData);
	}, [chartData]);
	useEffect(() => {
		selectedQuery("monthly")
	}, [selectedQuery]);
	return (
		<div className="admin_report">
			<div className="admin_dropdown_container">
			<select style={selectedQuery?.name == 'usersAge'?{display:'none'}:{}} onChange={(e) => selectedQuery(e.target.value)}>
				<option  value="daily">
					daily
				</option>
				<option value="weekly">weekly</option>
				<option selected value="monthly">monthly</option>
			</select>
			<select className="queriesComboBox" onChange={(e) => selectFunction(e.target.value)}>
				<option selected value="online users query">
					online users query
				</option>
				<option value="registration users query">registration users query</option>
				<option value="user's age query">user's age query</option>
				<option value="user's posts query">user's posts query</option>
			</select>
			</div>
			
			<h1 style={{ fontFamily: "input_font" }}>Reports</h1>

			<div>
				{chartData && (
					<Line
						height={500}
						width={900}
						data={chartData}
						options={{
							responsive: true,
							title: { text: "THICCNESS SCALE", display: true },
							scales: {
								yAxes: [
									{
										ticks: {
											autoSkip: true,
											maxTicksLimit: 10,
											beginAtZero: true,
										},
										gridLines: {
											display: false,
										},
									},
								],
								xAxes: [
									{
										gridLines: {
											display: false,
										},
									},
								],
							},
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default Reports;
