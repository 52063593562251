import React, { useContext,useEffect } from "react";
import { DataContext } from "../DataContext";
import Notification from "./Notification";
import "../../styles/Notification.css";
import axios from 'axios'
function NotificationList() {
	const { notifications,setNotifications,currentUserData } = useContext(DataContext);
	useEffect(()=>{
		let notificationsStringIds=""
		for (let i = 0; i < notifications.length; i++) {
			if (notifications[i]?.targetId)
			notificationsStringIds +=
			notifications[i]?.id + ",";
		}
		axios
		.put(
			"https://champiz-server.herokuapp.com/notfications/updateIsRead",
			{ str: notificationsStringIds,id:currentUserData.id },
			{
				headers: {
					"access-token":
						localStorage.getItem("access-token"),
				},
			}
		)
		.then((res) => {
			let tempNotifications = res.data
			tempNotifications = tempNotifications.map((not) => {
				return {
					...not,
					date: new Date(not.date).toString(),
					body:  not.body+" "+not.sourceName,
					link:not.body.includes('cheer') 
					|| not.body.includes('comment')
					?
					`feed/${not.eventId}`
					:
					`profile${not.eventId}`
				};
			})
			.reverse()
			setNotifications(tempNotifications);
		});
	},[])
	return (
		<div className="notifications_dropdown_container shadow_hover animate__animated animate__fadeIn animate__fast">
			{notifications.map((notific) => (
				<Notification
					body={notific.body}
					link={notific.link}
					date={notific.date}
				/>
			))}
		</div>
	);
}

export default NotificationList;
