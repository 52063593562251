import React, {  useState } from "react";
import { ImArrowRight } from "react-icons/im";
import Fab from "@material-ui/core/Fab";
import ForwardIcon from "@material-ui/icons/Forward";
import PasswordInput from "../PasswordInput";
import { TextField } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(2),
			width: "25ch",
			display: "flex",
			justifyContent: "center",
		},
		display:'flex',
		flexDirection:'row'
	},
	margin: {
		margin: theme.spacing(2),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
}));


export default function SignUp({ switchSign }) {
	const classes = useStyles();
	const [state, setState] = useState({username:"",password:"",email:"",gender:false,birthDate:'12/14/1995'})
	const [checkBoxLabels, setCheckBoxLabels] = useState(["Male","Female","Other"])
	const [checkBoxVal, setCheckBoxVal] = useState({"Male":false,"Female":false,"Other":false})
	const register = async() =>{
        let res = await axios.post('https://champiz-server.herokuapp.com/auth/register', state)
		if(res){
			alert(res.data)
			if(res.data == 'register succeded')
			switchSign()
		}else
		alert('register failed')
    }

	const handleChange = (event)=>{
		setState(prev=>{return (
			{...prev,[event.target.name]:event.target.value}
		)})
		
	}

	const handleCheckBox = (event)=> {
		const checkbox = event.target;
		const tempObj = {"Male":false,"Female":false,"Other":false}
		Object.keys(checkBoxVal).forEach(n=>{
			if(checkbox.checked&&checkbox.name != n)
				tempObj[n]= false
		})
		if(checkbox.checked)
		tempObj[checkbox.name] = true
	
		setCheckBoxVal(tempObj)
		if(checkbox.checked)
		setState(prev=>{return {...prev,["gender"]:checkbox.name}})
		
		}
	
	return (
		<div className="sign_container register ">
			<Fab
				onClick={switchSign}
				style={{ alignSelf: "flex-end" ,backgroundColor:'white',border:'2px solid var(--blue)'}}
				size="small"
				
				aria-label="add">
				<ForwardIcon style={{color:'black'}}/>
			</Fab>

				<div className={classes.root} >
				<TextField
					id="outlined-secondary"
					label="Email"
					variant="outlined"
					color="#4e8494"
					name="email"
					onChange={handleChange}
				/>
				<TextField
					id="outlined-secondary"
					label="Username"
					variant="outlined"
					color="#4e8494"
					name="username"
					onChange={handleChange}
				
					/>
				
				</div>
				<div className={classes.root}>
				<PasswordInput handleChange={handleChange} name={"password"} label={"Password"} onChange={handleChange}/>
				<PasswordInput handleChange={handleChange} name={"confirm"} label={"Confirm"} onChange={handleChange} />
				</div>


			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container justify="space-around">
					<KeyboardDatePicker
						margin="normal"
						id="date-picker-dialog"
						label="Birth date"
						format="MM/dd/yyyy"
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
						onChange={handleChange}
						name={'birthDate'}
						/>
				</Grid>
			</MuiPickersUtilsProvider>
			<div className="gender">
				<p>Gender</p>
				<div className="gender_properties">
					{
						checkBoxLabels.map((gen)=>{
							return  (
								<FormControlLabel
								style={{color:'#4e8494'}}
									control={<Checkbox checked={checkBoxVal[gen]} onChange={handleCheckBox} name={gen} color="primary" />}
									label={gen}
									
								/>
							)
						})
					}
				
				</div>
				                                                                                                                                                                                         
			</div>

			<Button className="sign_btn" onClick={register} variant="contained" >Sign up</Button>
		</div>
	);
}
