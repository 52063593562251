import React,{useContext,useEffect} from 'react'
import Post from '../feed/Post';
import { DataContext } from '../DataContext';
import Search from '../Search';
function PostManagement({allPosts}) {
	const{ users,componentsSearchCondition,handlePostTime} = useContext(DataContext)
	
	return (
		<div className={'posts_management_container'}>
			
			{users &&
					allPosts
					.filter(
						componentsSearchCondition
							? componentsSearchCondition
							: (element) => element
					)
					.filter((post) => handlePostTime(post.publishDate) > 0)
					.reverse()
					.map((post) => {
						let postUser = users.find(
							(user) => user.id == post.postUserId
						);
						return (
						
							<Post
								currentPostUser={postUser}
								profileImg={post.profileImg}
								contentImg={post.imageSource}
								content={post.description}
								date={post.publishDate}
								cheers={post.cheers}
								postId={post.id}
								isAdmin={true}
							/>
						);
					})}
		</div>
	)
}

export default PostManagement
