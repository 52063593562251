const ProgressBar = (props) => {
	const { bgcolor, completed } = props;
  
	const containerStyles = {
	  height: 20,
	  width: '100%',
	  backgroundColor: "#e0e0de",
	  borderRadius: 50
	}
  
	const fillerStyles = {
	  height: '100%',
	  width: `${completed}%`,
	  backgroundColor: bgcolor,
	  borderRadius: 'inherit',
	  textAlign: 'right'
	}
  
	const labelStyles = {
	  padding: 5,
	  color: 'var(--yellow)',
	  fontWeight: 'bold',
	  fontFamily:'input_font',
	  fontSize:'12px'
	}
  
	return (
	
	  <div style={containerStyles}>
		<div style={fillerStyles}>
		  <span style={labelStyles}>{`${completed}%`}</span>
		</div>
	  </div>
	);
  };
  
  export default ProgressBar;