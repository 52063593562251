import React,{useState,useEffect,useContext} from 'react'
import AdminSideBar from './AdminSideBar'
import AdminScreen from './AdminScreen'
import { DataContext } from '../DataContext'
import '../../styles/Admin.css'
function AdminPanel() {
	const [screenToPresent,setScreenToPresent] = useState("users")
	const [allPosts,setAllPosts] = useState([])
	const {setPosts,getAllPosts,posts} = useContext(DataContext)
	useEffect(()=>{
		getAllPosts()
				.then(res=>{
					console.log(res.data)
					setAllPosts(res.data)
				})
	},[])
	return (
		<div className="adminPanel_container">
			<AdminScreen allPosts={allPosts} screenToPresent={screenToPresent}/>
			<AdminSideBar setScreenToPresent={setScreenToPresent} screenToPresent={screenToPresent}/>
		</div>
	)
}

export default AdminPanel
