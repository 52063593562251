import React, { useState,useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button } from "@material-ui/core";
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(2),
			width: "40ch",
			display: "flex",
			justifyContent: "center",
		},
	},
	margin: {
		margin: theme.spacing(2),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
}));

export default function SignIn({ switchSign }) {
	const [values, setValues] = useState({
		email: "",
		password: "",
		showPassword: false,
	});

	

	const classes = useStyles();

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	
	const validate = () => {
		if (!values.email.includes("@") || values.email.length < 7){
			alert('wrong email')
			return false;
		}
		if (values.password.length < 6){
			alert('password must contains more then 5 digits')
			return false;
		}
		
		return true;
	};
	
	const  handleClick = async() => {
		 if(!validate())
		 return;

		let res = await axios.post('https://champiz-server.herokuapp.com/auth/login',{email:values.email,password:values.password})
		if(res.data.message){
			alert(res.data.message)
			return;
		}
		localStorage.setItem("access-token",res.data)
		localStorage.setItem("email",values.email)
		window.location.reload()
		
	};
	
	
	return (
		<div className="sign_container">
			<div className="crowns"></div>
			<div className={classes.root}>
				<TextField
					id="outlined-secondary"
					label="Email"
					variant="outlined"
					color="#4e8494"
					onChange={handleChange("email")}
				/>
				<FormControl
					className={clsx(classes.margin, classes.textField)}
					variant="outlined">
					<InputLabel htmlFor="outlined-adornment-password">
						Password
					</InputLabel>
					<OutlinedInput
						id="outlined-adornment-password"
						type={values.showPassword ? "text" : "password"}
						value={values.password}
						onChange={handleChange("password")}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end">
									{values.showPassword ? (
										<Visibility />
									) : (
										<VisibilityOff />
									)}
								</IconButton>
							</InputAdornment>
						}
						labelWidth={70}
					/>
				</FormControl>
			</div>

		
			<br />
			<p className="connection_join" >
				New to champiz? <span onClick={switchSign}>Sign up</span>
			</p>
			<Button className="sign_btn" onClick={handleClick} variant="contained">Sign in </Button>
		</div>
	);

}
