import React, { useEffect, useContext } from "react";
import Champiz from "../images/Champiz.svg";
import { BsFillBellFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { CgFeed } from "react-icons/cg";
import { IoMdHelpCircle } from "react-icons/io";
import { GiRank3 } from "react-icons/gi";
import { HiChat } from "react-icons/hi";
import { IoLogOutOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import "../styles/NavBar.css";
import { useState } from "react";
import { DataContext } from "./DataContext";
import NotificationList from "../components/notfication/NotificationList";
import { Image } from "cloudinary-react";
import { useHistory } from "react-router-dom";
const ChosenPageStyle = {
	color: "#4e8494",
	backgroundColor: "#fffbbd",
	borderRadius: "15px",
	padding: "5px",
};

export default function NavBar({ showNotfications, setShowNotfications }) {
	const [showNav, setShowNav] = useState(false);
	const history = useHistory()
	const { ChosenPage, ActiveIconNavBar, currentUserData, notifications } =
		useContext(DataContext);

	function logOut() {
		localStorage.removeItem("access-token");
		window.location.reload();
	}

	function handleNotifications() {
		if (notifications.length == 0) return;
		setShowNotfications((prev) => !prev);
	
	}
	useEffect(() => {
		if (localStorage.getItem("access-token") != undefined) 
		setShowNav(true);
		else
		history.push('/')
	}, []);
	return (
		<>
			<div className="space_div navbar"></div>
			<div className="NavBar_container">
				<Link to={"/"}>
					<img className="Champiz_logo" src={Champiz} alt="Champiz" />
				</Link>
				{showNav && currentUserData.isAdmin == 1 && (
					<div className="links_container">
						<Link className="Link" to={"/profile"}>
							<h3
								onClick={ActiveIconNavBar}
								style={
									ChosenPage.includes("profile")
										? ChosenPageStyle
										: { color: "#fffbbd" }
								}
								className="navbar_links animate__animated animate__zoomIn">
								Profile
							</h3>
						</Link>
						<Link className="Link" to={"/ranking"}>
							<h3
								onClick={ActiveIconNavBar}
								style={
									ChosenPage === "ranking"
										? ChosenPageStyle
										: { color: "#fffbbd" }
								}
								className="navbar_links animate__animated animate__zoomIn">
								Ranking
							</h3>
						</Link>
						<Link className="Link" to={"/feed"}>
							<h3
								onClick={ActiveIconNavBar}
								style={
									ChosenPage === "feed"
										? ChosenPageStyle
										: { color: "#fffbbd" }
								}
								className="navbar_links animate__animated animate__zoomIn">
								Feed
							</h3>
						</Link>

						<Link className="Link" to={"/help"}>
							<h3
								onClick={ActiveIconNavBar}
								style={
									ChosenPage === "help"
										? ChosenPageStyle
										: { color: "#fffbbd" }
								}
								className="navbar_links animate__animated animate__zoomIn">
								Help
							</h3>
						</Link>
						<Link className="Link" onClick={logOut}>
							<h3
								onClick={ActiveIconNavBar}
								className="navbar_links animate__animated animate__zoomIn">
								Logout
							</h3>
						</Link>
					</div>
				)}{" "}
				{currentUserData.isAdmin == 0 &&<div style={{position:'absolute',right:'100px'}}>
				<Link className="Link" onClick={()=>{logOut();}}>
							<h3
								onClick={ActiveIconNavBar}
								className="navbar_links animate__animated animate__zoomIn">
								Logout
							</h3>
						</Link>
				</div>}
				
				{showNav && currentUserData.isAdmin==1&&(
					<div className="bell_container" style={{ display: "flex" }}>
						<BsFillBellFill
							onClick={handleNotifications}
							className="bell"
							size={20}
						/>
						<span
							onClick={handleNotifications}
							className="notfications">
							{notifications.filter(not=>not.isRead==0).length >= 100
								? "99+"
								: notifications.filter(not=>not.isRead==0).length}
						</span>
					</div>
				)}
				<div>{showNotfications && <NotificationList />}</div>
				{showNav &&<div style={{ marginRight: "40px" }}>
					<div className="circle_container_small">
						<div className="circle">
							<Image
								className="circle_Image"
								publicId={currentUserData.profileImage}
								cloudName={"Champiz"}
							/>
						</div>
					</div>
				</div>}
			</div>
			<div className="space_div navbar_mobile"></div>
			{showNav && (
				<div className="NavBar_container navbar_mobile">
					<div className="links_container_mobile">
						<Link
							className="Link"
							onClick={ActiveIconNavBar}
							to={"/profile"}>
							<CgProfile
								className="navbar_icon animate__animated animate__zoomIn"
								color={
									ChosenPage === "profile"
										? "white"
										: "#fffbbd"
								}
								size={25}
							/>
						</Link>
						<Link
							className="Link"
							onClick={ActiveIconNavBar}
							to={"/ranking"}>
							<GiRank3
								className="navbar_icon animate__animated animate__zoomIn"
								color={
									ChosenPage === "ranking"
										? "white"
										: "#fffbbd"
								}
								size={25}
							/>
						</Link>
						<Link className="Link" to={"/feed"}>
							<CgFeed
								onClick={ActiveIconNavBar}
								className="navbar_icon animate__animated animate__zoomIn"
								color={
									ChosenPage === "feed" ? "white" : "#fffbbd"
								}
								size={25}
							/>
						</Link>
						<Link className="Link">
							<HiChat
								onClick={ActiveIconNavBar}
								className="navbar_icon animate__animated animate__zoomIn"
								color={
									ChosenPage === "chat" ? "white" : "#fffbbd"
								}
								size={25}
							/>
						</Link>
						<Link className="Link">
							<IoMdHelpCircle
								onClick={ActiveIconNavBar}
								className="navbar_icon animate__animated animate__zoomIn"
								color={
									ChosenPage === "help" ? "white" : "#fffbbd"
								}
								size={25}
							/>
						</Link>
						<Link className="Link">
							<IoLogOutOutline
								onClick={ActiveIconNavBar}
								className="navbar_icon animate__animated animate__zoomIn"
								color={
									ChosenPage === "logout"
										? "white"
										: "#fffbbd"
								}
								size={25}
							/>
						</Link>
					</div>
				</div>
			)}
		</>
	);
}
