import React,{useState,useEffect} from 'react'

function Cheer({small,postCheer,id,postId,action}) {
	const [cheersImg,setCheersImg] = useState('uncheers')
	useEffect(()=>{
		if(localStorage.getItem("likes")?.includes(postId+','))
		setCheersImg('cheers')
	},[])
	function cheer(){
		if(postCheer)
		postCheer(id,postId,action)
		if(cheersImg=='uncheers')
			setCheersImg('cheers')
		else
			setCheersImg('uncheers')
	}
	return (
		<img className='post_content_img_icons' style={small&&{width:'25px',height:'25px'}} onClick={cheer} src={require(`../images/${cheersImg}.svg`).default} alt=""/>
	)
}

export default Cheer
