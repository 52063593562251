import React, { useEffect, useState, useContext } from "react";
import RankingBar from "./RankingBar";
import RankingHeader from "./RankingHeader";
import RankingStrip from "./RankingStrip";
import RankingButton from "./RankingButton";
import "../../styles/Ranking.css";
import { FaCrown } from "react-icons/fa";
import ScrollButton from "../ScrollButton";
import { DataContext } from "../DataContext";
export default function Ranking() {
	const {
		users,
		componentsSearchCondition,
		setComponentsSearchCondition,
		currentUserData,
		LevelUpRank,
		limitRank
	} = useContext(DataContext);
	const [rankingStripsToRender, setRankingStripsToRender] = useState(users);
	const [buttonFocus, setButtonFocus] = useState(1);
	const [chosenRank, setChosenRank] = useState("Diamond");
	const [startPageNumber, setStartPageNumber] = useState(1);
	const sliceKey = 10;
	const [currentUsers, setCurrentUsers] = useState([]);
	const [genderFilter, setGenderFilter] = useState(prev=>dummy=>dummy);
	const [numOfButtons, setNumOfButtons] = useState(calcButtonsNumber());
	function calcButtonsNumber() {
		return Math.ceil(
			users.filter(genderFilter).filter((user) => user.userRank == chosenRank).length /
				sliceKey
		) > 5
			? 5
			: Math.ceil(
					users.filter(genderFilter).filter((user) => user.userRank == chosenRank).length /
						sliceKey
			  );
	}

	function handleNavigate(pageNumber, type) {
		const maxButtons = Math.ceil(users.length / sliceKey);
		const offset = pageNumber + 5;
		if (type == "Next") {
			if (maxButtons - offset + 1 > 0 && maxButtons - offset + 1 < 5) {
				setNumOfButtons(maxButtons - offset + 1);
			} else if (maxButtons - offset > 0) setNumOfButtons(5);
			else return;

			setStartPageNumber((prev) => prev + 5);
		}
		if (type == "Back") {
			if (pageNumber != 1) setNumOfButtons(5);
			if (pageNumber > 5) setStartPageNumber((prev) => prev - 5);
		}
	}

	//handle mobile ranking bar presentation
	function openMobileBar() {
		let ranking_bar = document.getElementsByClassName(
			"ranking_bar_container"
		)[0];
		let blur_background =
			document.getElementsByClassName("blur_background")[0];
		ranking_bar.style.display = "flex";
		blur_background.style.display = "flex";
		ranking_bar.className += " animate__animated animate__slideInRight";
	}

	function closeMobileBar() {
		let ranking_bar = document.getElementsByClassName(
			"ranking_bar_container"
		)[0];
		let blur_background =
			document.getElementsByClassName("blur_background")[0];
		ranking_bar.style.display = "none";
		blur_background.style.display = "none";
	}
	//update the num of buttons
	useEffect(() => {
		setNumOfButtons(calcButtonsNumber());
		setCurrentUsers(
			users
				.filter((user) => user.userRank == chosenRank)
				.slice(0, sliceKey)
		);
		setButtonFocus(1);
	}, [chosenRank]);
	useEffect(() => {
		console.log(currentUserData)
		//close and open the bar when resize window
		window.addEventListener("resize", () => {
			try {
				if (window.innerWidth > 800)
					document.getElementsByClassName(
						"ranking_bar_container"
					)[0].style.display = "flex";
				else
					document.getElementsByClassName(
						"ranking_bar_container"
					)[0].style.display = "none";
			} catch {
			}
		});
		//reset the search condition
		setComponentsSearchCondition((prev) => (e) => e);
	}, []);
	useEffect(() => {
	
		setCurrentUsers(
			users
				.filter((user) => user.userRank == chosenRank)
				.slice(0, sliceKey)
		);
		console.log(users
			.filter((user) => user.userRank == chosenRank)
			.slice(0, sliceKey))
	}, [users]);

	return (
		<div className="ranking_container">
			<ScrollButton />
			<div className="ranking_main">
				<RankingHeader rank={chosenRank} limitRank={limitRank} LevelUpRank={LevelUpRank} xp={currentUserData.xp} myRank={currentUserData.userRank}/>
				{/* check if there is search condition if does show all the results else show only the page result */}
				{/* without condition */}
				{rankingStripsToRender.filter(
					componentsSearchCondition
				).length == users.length
					? currentUsers
							.filter((user) => user.userRank == chosenRank)
							.filter(genderFilter)
							.sort((a,b)=>a.xp-b.xp)
							.reverse()
							.map((user,index) => (
								<RankingStrip
									user={user}
									imgSrc={user.profileImage}
									number={((buttonFocus-1)*10)+(index+1)}
								/>
							))
					: // with condition
					  rankingStripsToRender
							.filter(
								componentsSearchCondition
							)
							.filter((user) => user.userRank == chosenRank)
							.filter(genderFilter)
							.map((user,index) => (
								<RankingStrip
									user={user}
									imgSrc={user.profileImage}
									number={index+1}
								/>
							))}
				{/* check if search is include so no need the ranking buttons else make the show */}
				{rankingStripsToRender.filter(
					componentsSearchCondition
				).length == users.length && (
					<div className="ranking_pageNumber_container">
						<h5
							style={{ cursor: "pointer" }}
							onClick={() =>
								handleNavigate(startPageNumber, "Back")
							}>
							◀ Back
						</h5>
						<div className="ranking_page_buttons_container">
							{[...Array(numOfButtons).keys()].map((num) => (
								<RankingButton
									setStartPageNumber={setStartPageNumber}
									sliceKey={sliceKey}
									setCurrentUsers={setCurrentUsers}
									users={users}
									genderFilter={genderFilter}
									chosenRank={chosenRank}
									num={num + startPageNumber}
									focus={
										num + startPageNumber == buttonFocus
											? true
											: false
									}
									setButtonFocus={setButtonFocus}
								/>
							))}
						</div>
						<h5
							style={{ cursor: "pointer" }}
							onClick={() =>
								handleNavigate(startPageNumber, "Next")
							}>
							Next ▶
						</h5>
					</div>
				)}
			</div>
			<RankingBar currentUserGender={currentUserData.gender} setGenderFilter={setGenderFilter} setChosenRank={setChosenRank} />
			<div className="arrow_mobile_icon" onClick={openMobileBar}>
				<FaCrown size={25} />
				<div className="arrow_mobile_text">
					<p>Rank</p>
				</div>
			</div>
			<div onClick={closeMobileBar} className="blur_background"></div>
		</div>
	);
}
