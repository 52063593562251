
import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import '../styles/LoadingPage.css'
export function LinearIndeterminate() {
  return (
    <Box sx={{ width: '60%' }}>
      <LinearProgress />
    </Box>
  );
}

function LoadingPage() {
	return (
		<div className="loading_page_container">
			<img src={require('../images/cLogo.png').default}></img>
			<LinearIndeterminate/>
		</div>
	)
}

export default LoadingPage
