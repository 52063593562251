import React, { useState } from "react";
import { Image } from "cloudinary-react";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import Button from "@mui/material/Button";
import axios from "axios";

function UserToManage({ user, setUsers,deleteUser }) {
	const [blockText, setBlockText] = useState("Block");
	
	function blockUser() {
		if (window.confirm("Are you sure you want to block this user?")) {
			let days = Number(prompt("How many days to block this user?"));
			let date = new Date(new Date().getTime()+(days*24*60*60*1000));
			var pad = function (num) {
				return ("00" + num).slice(-2);
			};
			date =
				date.getUTCFullYear() +
				"-" +
				pad(date.getUTCMonth() + 1) +
				"-" +
				pad(date.getUTCDate()) +
				" " +
				pad(date.getUTCHours()) +
				":" +
				pad(date.getUTCMinutes()) +
				":" +
				pad(date.getUTCSeconds());
			axios
				.put(
					"https://champiz-server.herokuapp.com/users/block",
					{ id: user.id, date: date },
					{
						headers: {
							"access-token":
								localStorage.getItem("access-token"),
						},
					}
				)
				.then((res) => {
					setBlockText("Blocked");
				});
		}
	}
	return (
		<div className="user_to_manage">
			<div className="circle_container_big" style={{ width: "80px" }}>
				<div className="circle">
					<Image
						className="circle_Image"
						publicId={user.profileImage}
						cloudName={"Champiz"}
					/>
				</div>
			</div>
			<p>{user.username}</p>
			<div className="admin_user_control_buttons">
				<Button
					onClick={blockUser}
					variant="contained"
					color={"warning"}
					endIcon={<BlockIcon />}>
					{blockText}
				</Button>
				<Button
					onClick={()=>deleteUser(user)}
					variant="contained"
					color={"error"}
					endIcon={<DeleteIcon />}>
					Delete
				</Button>
			</div>
		</div>
	);
}

export default UserToManage;
