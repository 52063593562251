import React, { useEffect,useState,useContext } from "react";
import { Image } from "cloudinary-react";
import axios from 'axios'
import Cheer from "../Cheer";
import { DataContext } from "../DataContext";
function Comment({ user, content,cheers,commentId,isAdmin,currentUserData }) {
	const [wasCheers, setWasCheers] = useState(false);
	const [cheersToPresent, setCheersToPresent] = useState(cheers);
	const {deleteComment} = useContext(DataContext)
	function handleWithCheers(id,postId,action) {
		setWasCheers((prev) => !prev);
		if(cheersToPresent<=0&&action=='cheersMinus')
		return
		setCheersToPresent(prev=>action=='cheersPlus'?prev+1:prev-1)
		axios
			.put(
				`https://champiz-server.herokuapp.com/comments/${action}`,
				{
					id: commentId,
					sourceId: id,
				},
				{
					headers: {
						"access-token": localStorage.getItem("access-token"),
					},
				}
			)	
			.catch(err=>alert(err))
			
	}
	useEffect(()=>{
		setCheersToPresent(cheers)
	},[cheers])
	return (
		<div  className="post_comment">
			<div onClick={()=>{
				deleteComment(commentId)
				
		}} style={{position:'absolute',top:'-6px'
			,fontFamily:'input_font',left:'-5px'
			,backgroundColor:'red',borderRadius:'100px'
			,fontSize:'11px'
			,width:'15px'
			,height:'16px'
			,textAlign:'center'
			,verticalAlign:'center'
			,color:'white'
			,cursor:'pointer'
			,display:currentUserData.id == user.id||isAdmin?'block':'none'
			}}>
				X
			</div>
			<div className="post_comment_content">
				<div className="circle_container_big">
					<div className="circle">
						<Image
							className="circle_Image"
							publicId={user.profileImage}
							cloudName={"Champiz"}
						/>
					</div>
				</div>
				<div style={{width:'90%',marginLeft:'10px',minWidth:'400px'}}>
					<p className="post_comment_name" style={isAdmin?{color:'black'}:{}}>{user.username}</p>
					<p className="post_comment_content" style={isAdmin?{color:'black'}:{}}>
						{content}
					</p>
				</div>
			</div>{" "}
			<div className="post_comment_cheers_container" style={isAdmin?{display:'none'}:{}}>
			<Cheer small  postCheer={handleWithCheers} action={wasCheers?'cheersMinus':'cheersPlus'} id={user.id} postId={commentId}/>
			<span>{cheersToPresent}</span>
			</div>
			
		</div>
	);
}

export default Comment;
