import React from 'react'
import '../styles/Footer.css'
function Footer() {
	return (
		<div className="footer_container">
			<p style={{fontFamily:'tahoma',marginBottom:'5px'}}>&copy;{new Date().getFullYear()} Yoad & Yair Productions</p>
			
		</div>
	)
}

export default Footer
