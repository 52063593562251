import React, { useState,useContext } from "react";
import SignIn from "./SignIn";
import { DataContext } from "../DataContext";
import "../../styles/userConnection.css";
import SignUp from "./SignUp";
import { useEffect } from "react";
import axios from 'axios'
import { useHistory } from "react-router";
import LoadingPage from "../LoadingPage";
export default function Connection() {
	const [isSignUp, setIsSignUp] = useState(false);
	const [loading, setLoading] = useState(true);
	const {ActiveIconNavBar,currentUserData} = useContext(DataContext)
	const history = useHistory()
	const switchSign = () => {
		setIsSignUp((prev) => !prev);
	};
	useEffect(()=>{
		window.onscroll=null;
	},[])
	useEffect(()=>{
		
		if(localStorage.getItem("access-token")){
			axios	
			.get(`https://champiz-server.herokuapp.com/auth/validate`,{
				headers:{
					"access-token":localStorage.getItem("access-token")
				}
			})
			.then((res) => {
				setLoading(false)
				// console.log(res.data)
				// if the user is already logged push to the feed
			
			 if(res.data == "access confirmed"){
				ActiveIconNavBar()
				history.push('/feed')
			}
		  })
		}
		else{
			setTimeout(()=>setLoading(false),1000)
			
		}
	
	},[])

	if(!loading)
	return (
		<div className="connection_container">
			<div className="sign_wrapper">
				{isSignUp ? (
					<SignUp switchSign={switchSign} />
				) : (
					<SignIn switchSign={switchSign} />
				)}
			</div>
			<img className="connection_main_img" style={isSignUp?{width:'45%',height:'400px',marginTop:'11%'}:{}} src={require('../../images/sign_img.png').default} alt="" />
		</div>
	);
	else return (<LoadingPage/>)
}
