import React from 'react'
import { FaUsers } from 'react-icons/fa';
import { BsGraphUp } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';

function AdminSideBar({setScreenToPresent,screenToPresent}) {
	return (
		<div className="admin_sidebar">
			<div className="admin_sidebar_category" onClick={()=>setScreenToPresent("users")} style={{borderBottom:screenToPresent=='users'?'2px solid var(--blue)':''}}><FaUsers size={23}/> <p>User's Management</p></div>
			<div className="admin_sidebar_category" onClick={()=>setScreenToPresent("feed")} style={{borderBottom:screenToPresent=='feed'?'2px solid var(--blue)':''}}><FaRegEdit size={23}/><p>Posts Management</p></div>
			<div className="admin_sidebar_category" onClick={()=>setScreenToPresent("reports")} style={{borderBottom:screenToPresent=='reports'?'2px solid var(--blue)':''}}><BsGraphUp size={23}/><p>Reports</p></div>
		</div>
	)
}

export default AdminSideBar
