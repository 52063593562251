import React from 'react'
import Search from '../Search'
import LinearWithValueLabel from '../ProgressBar'
function ChatHeader() {
	return (
		<div className="chat_header_container">
			<p>The time is now</p>
			 <p>Start a conversation with your friends..</p>
			<Search percent={'100%'} location={'chat'}/>
			
		</div>
	)
}

export default ChatHeader
