import React, { Component, useEffect, useState,useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import LoadingPage from "./LoadingPage";
import { DataContext } from "./DataContext";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [Access, setAccess] = useState('loading');
    const [elementResult, setElementResult] = useState('loading');
	const {ChosenPage,currentUserData} = useContext(DataContext)
	const [loading,setLoading] = useState(true)
    useEffect(() => {
      if(localStorage.getItem("access-token")){
		axios	
        .get(`https://champiz-server.herokuapp.com/auth/validate`,{
            headers:{
                "access-token":localStorage.getItem("access-token")
            }
        })  .then((res) => {setAccess(res.data);console.log(res.data)}
		)
		.catch(err=>{setAccess("access denied");})
	  }else{
		  setLoading(false)
		setElementResult(<Route render={() =><Redirect to="/"/>}/> )
	  }
		
      
    },[]);
	useEffect(()=>{	
		
		if(Access=="access denied")
		setElementResult(<Route render={() =><Redirect to="/"/>}/> )
		else if(Access=="access confirmed")
		setElementResult(<Route render={() =><Component/>}/>)
	},[ChosenPage,Access])
	useEffect(()=>{
		if(Object.keys(currentUserData).length>0)
		setLoading(false)
	},[currentUserData])
 if(!loading&&currentUserData.isAdmin == 1||!localStorage.getItem("access-token")) 
 return (
	elementResult
  );
else if(!loading&&currentUserData.isAdmin == 0)
	return <Route render={() =><Redirect to="/admin"/>}/>
else
	return <LoadingPage/>
};
