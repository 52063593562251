import React, { useContext, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import PopUp from "../PopUp";
import { Button } from "@material-ui/core";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner";
import { DataContext } from "../DataContext";
import { Image } from "cloudinary-react";
import "../../styles/Profile.scss";
import RankingStrip from "../ranking/RankingStrip";
export default function ProfileImage({
	user,
	followers,
	userId,
	profileImage,
	checkProfileUser,
	name,
}) {
	const {
		imgData,
		progress,
		handleImgChange,
		LoadingToUpload,
		uploadImage,
		setCurrentUserData,
		newRelation,
		followingUsers,
		deleteUser
	} = useContext(DataContext);
	const [imgSrc, setImgSrc] = useState(profileImage);
	const [showProfileImagePopUp, setshowProfileImagePopUp] = useState(false);
	const [showSettingsPopUp, setShowSettingsPopUp] = useState(false);
	const [showFollowersPopUp, setShowFollowersPopUp] = useState(false);
	const [changePasswordState, setChangePasswordState] = useState({'email':null,'oldPassword':null,'newPassword':null,'confirmPassword':null});
	function handleChangePassword(e){
		setChangePasswordState(prev=>{return {...prev,[e.target.name]:e.target.value}})
	}
	function submitNewPassword(){
		if(!(changePasswordState.email&&changePasswordState.oldPassword&&changePasswordState.newPassword&&changePasswordState.confirmPassword)){
			alert('you required to fill all the fields')
			return
		}
		if(changePasswordState.email != user.email){
			alert('email is wrong')
			return
		}
		if(changePasswordState.newPassword.length<6){
			alert('password must contain atleast 6 digits')
			return
		}
		if(changePasswordState.newPassword!=changePasswordState.confirmPassword){
			alert('new passord isnt fit to the confirm passoword')
			return
		}

		axios.put(
			"https://champiz-server.herokuapp.com/auth/updatePassword",
			{ email: user.email, password:changePasswordState.newPassword,oldPassword:changePasswordState.oldPassword  },
			{
				headers: {
					"access-token": localStorage.getItem("access-token"),
				},
			}
		).then(res=>console.log(res))


	}
	function handleProfileImagePopUpOpen() {
		setshowProfileImagePopUp(true);
	}
	function handlePopUpClose() {
		setshowProfileImagePopUp(false);
		setShowSettingsPopUp(false)
		setShowFollowersPopUp(false)
	}
	function uploadProfileImage() {
		uploadImage().then((res) => {
			const imagePublicId = res.data.public_id;

			axios.put(
				"https://champiz-server.herokuapp.com/users/updateProfileImage",
				{ id: userId, profileImage: imagePublicId },
				{
					headers: {
						email: localStorage.getItem("email"),
						"access-token": localStorage.getItem("access-token"),
					},
				}
			);

			axios
				.get("https://champiz-server.herokuapp.com/users/getCurrentUserData", {
					headers: {
						id: userId,
						"access-token": localStorage.getItem("access-token"),
					},
				})
				.then((res) => {
					setCurrentUserData(res.data);
					handlePopUpClose();
					window.location.reload();
				});
		});
	}
	useEffect(() => {
		setImgSrc(profileImage);
		console.log(profileImage);
	}, [profileImage]);

	if (profileImage)
		return (
			<div className="profile_image_container">
				<div
					className="circle_container_big"
					style={{ width: "300px", marginTop: "40px" }}>
					<div className="circle">
						<Image
							className="circle_Image"
							publicId={imgSrc ? imgSrc : ""}
							cloudName={"Champiz"}
						/>
					</div>
				</div>

				<PopUp
					imgSrc={imgSrc}
					rankImg={user.userRank}
					content={
						<div className="post_img_input">
							<div className="post_img_input_holder">
								{
									<h5
										style={{
											position: "absolute",
											zIndex: 333,
										}}
										onClick={() =>
											document
												.getElementById(
													"profileImg_input"
												)
												.click()
										}>
										edit ✎
									</h5>
								}
								<img src={imgData} alt="" />
							</div>
							<div style={{ height: "80px" }} />
							{!LoadingToUpload && (
								<Button
									onClick={uploadProfileImage}
									className="post_img_input_btn"
									variant="contained"
									color="primary">
									Submit
								</Button>
							)}
							{LoadingToUpload && (
								<LoadingSpinner
									className="post_img_input_btn"
									value={progress}
								/>
							)}
						</div>
					}
					name={name}
					showPopUp={showProfileImagePopUp}
				/>
					<PopUp
					imgSrc={imgSrc}
					rankImg={user.userRank}
					content={
						<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
							<div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
								<p className="profile_settings_label">Email</p>
								<input className="profile_settings_input" onChange={handleChangePassword} name="email" type="text" />
								
								<p className="profile_settings_label">Old password</p>
								<input className="profile_settings_input" type="password"  onChange={handleChangePassword} name="oldPassword"  type="text" />
								
								<p className="profile_settings_label">New password</p>
								<input className="profile_settings_input" type="password"  onChange={handleChangePassword} name="newPassword" type="text" />
								
								<p className="profile_settings_label">Confirm password</p>
								<input className="profile_settings_input" type="password"  onChange={handleChangePassword} name="confirmPassword" type="text" />
								<button id="settings_change_password" className="profile_settings_btn" onClick={submitNewPassword}>Submit</button>
							</div>
							<div style={{height:'20vh',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
								<p className="profile_settings_label"><span style={{color:'red',fontWeight:'bold'}}>Warning:</span> <br/> if you choose to delete your user there is no way back choose wisely!</p>
								<button onClick={()=>{deleteUser(user);localStorage.removeItem('access-token');window.location.reload()}} id="settings_delete" className="profile_settings_btn">Delete user</button>
								<div style={{height:'20px'}}></div>
							</div>
						</div>
					}
					name={name}
					showPopUp={showSettingsPopUp}
				/>

				<PopUp
					imgSrc={imgSrc}
					rankImg={user.userRank}
					content={
						<div>
							{followingUsers.map(
								user=>
								<RankingStrip
								user={user}
								imgSrc={user.profileImage}
								number={'🔥'}
								profile
							/>
							)}
						</div>
					}
					name={name}
					showPopUp={showFollowersPopUp}
				/>

				<div
					onClick={
						LoadingToUpload == false
							? handlePopUpClose
							: null
					}
					className="blur_background"
					style={
						showProfileImagePopUp
						||showSettingsPopUp
						||showFollowersPopUp
							? { display: "flex" }
							: { display: "none" }
					}></div>

				<div
					className={"edit_profile_img_container shadow_hover"}
					style={{
						display: checkProfileUser(userId) ? "flex" : "none",
					}}
					onClick={handleProfileImagePopUpOpen}>
					<FiEdit className="edit_profile_image_icon" size={25} />
				</div>
				<div className="follow_profile_container">
					{!followingUsers.find(
						(followingUser) => followingUser.id == user.id
					) && !checkProfileUser(userId) ? (
						<button
							onClick={() => newRelation(user)}
							className="follow_btn shadow_hover">
							follow
						</button>
					) : (
						!checkProfileUser(userId) && (
							<button  className="follow_btn shadow_hover">
								unfollow
							</button>
						)
					)}
					{checkProfileUser(userId) && (
						<button onClick={()=>setShowSettingsPopUp(prev=>!prev)} className="follow_btn shadow_hover">
							Settings
						</button>
					)}

					<button onClick={()=>setShowFollowersPopUp(prev=>!prev)} className="follow_btn shadow_hover">
						{followers} followers
					</button>
				</div>

				{checkProfileUser(userId) && <div></div>}

				<input
					onChange={handleImgChange}
					style={{ display: "none" }}
					id="profileImg_input"
					type="file"
					accept="image/*"
				/>
			</div>
		);
	else return "loading";
}
