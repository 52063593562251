import React, { useState, useEffect, useContext } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import { Image } from "cloudinary-react";
import { DataContext } from "../DataContext";
import axios from "axios";
function Conversation({
	imgSrc,
	name,
	chatNotifications,
	status,
	openChatPopUp,
	currentUserData,
	userId,
	messages,
	setMessages,
	rank,
	chatId
}) {
	const [chatStar, setChatStar] = useState(false);
	const [chatNotificationsToRender, setChatNotificationsToRender] =
		useState(chatNotifications);
	const [conversationMessages, setConversationMessages] = useState(messages);
	const { createConversation,deleteChat } = useContext(DataContext);
	function handleChangeStar() {
		setChatStar((prev) => !prev);
	}

	useEffect(() => {
		setConversationMessages(messages);
	}, [messages]);

	// useEffect(() => {
	// 	console.log("real messages")
	// 	console.log(conversationMessages)
	// }, [conversationMessages]);

	return (
		<div className="conversation_container">
			<div
				onClick={() => {
					let messagesStringIds = "";
					let lastId = -1;
					for (let i = 0; i < conversationMessages.length; i++) {
						if (conversationMessages[i]?.id)
							messagesStringIds +=
								conversationMessages[i]?.id + ",";
						else {
							if (lastId == -1)
								lastId = conversationMessages[i - 1]?.id + 1;
							else lastId = lastId + 1;
							messagesStringIds += lastId + ",";
						}
					}

					axios
						.put(
							"https://champiz-server.herokuapp.com/chat/updateIsRead",
							{ str: messagesStringIds },
							{
								headers: {
									"access-token":
										localStorage.getItem("access-token"),
								},
							}
						)
						.then((res) => {
							setMessages(res.data);
						});
					createConversation(name, rank, imgSrc, userId);
				}}
				className="conversation_name_content_img_wrapper">
				<div className="circle_container_mid">
					<div className="circle">
						<Image
							className="circle_Image"
							publicId={imgSrc}
							cloudName={"Champiz"}
						/>
					</div>
				</div>

				<div className="conversation_name_content">
					<h6 className="conversation_name">{name}</h6>
					<p className="conversation_content">
						{conversationMessages[conversationMessages.length - 1]
							?.body || "start conversation"}
					</p>
				</div>
			</div>
			<div className="coversation_icons_status_wrapper">
				<div className="conversation_icons">
					{conversationMessages.filter(
						(msg) =>
							msg.isRead == 0 &&
							msg.senderId != currentUserData.id
					).length > 0 && (
						<div className="conversation_notfication">
							{
								conversationMessages.filter(
									(msg) =>
										msg.isRead == 0 &&
										msg.senderId != currentUserData.id
								).length
							}
						</div>
					)}
					
					<DeleteIcon onClick={()=>deleteChat(chatId)} fontSize="small" style={{ color: "gray" }} />
				</div>
				<div
						onClick={openChatPopUp}
						className="conversation_status_container">
						<div
							style={
								status == "online"
									? { backgroundColor: "#32CD32" }
									: { backgroundColor: "red" }
							}
							className="conversation_status_flag"></div>
						<p>{status}</p>
					</div>
			</div>
		</div>
	);
}

export default Conversation;
