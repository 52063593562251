import React, { useContext, useEffect } from "react";
import Cheers from "../../images/cheers.svg";
import Friendship from "../../images/friendship.svg";
import Message from "../../images/message.svg";
import DateIcon from "../../images/date.png";
import { Image } from "cloudinary-react";
import { DataContext } from "../DataContext";
import { Link } from "react-router-dom";
import axios from 'axios'
function RankingStrip({ number, user,imgSrc,profile }) {
	const {createConversation,newRelation,currentUserData,setChosenPage,deleteRelationship,followingUsers,setFollowingUsers} = useContext(DataContext)

useEffect(()=>{
console.log(followingUsers)
},[followingUsers])
	return (
		<div style={profile?{width:'100%',justifyContent:'space-evenly'}:{}} className="ranking_strip_container">
			<div className="ranking_number_user_img_wrap">
				<h3 className="ranking_number">{number}</h3>
				<div className="circle_container_big" style={{width:'80px'}}>
					<div className="circle">
						<Image
							className="circle_Image"
							publicId={imgSrc?imgSrc:''}
							cloudName={"Champiz"}
						/>
					</div>
				</div>

				
			</div>
			<div className="ranking_strip_name_and_icons_container">
						<Link to={'feed'}>
						{currentUserData.id!=user.id?<img  style={{ width: "40px" ,cursor:'pointer'}} onClick={()=>{createConversation(user.username,user.userRank,imgSrc,user.id);setChosenPage('feed');}} src={Message} alt="" />
						:<div style={{width:'40px'}}></div>}
						</Link>
					<h2  className="ranking_strip_name"><a className="ranking_strip_name_hyperlink" href={`/profile${user.id}`}>{user.username}</a></h2>
						{currentUserData.id!=user.id?<div className="ranking_strip_add_pals_btn">
							{!followingUsers.find(followingUser=>followingUser.id==user.id)?<span onClick={()=>newRelation(user)}>
								follow
							</span>
							:<span onClick={()=>deleteRelationship(currentUserData.id,user.id)}>
							unfollow
						</span>}
						</div>:<div className="ranking_strip_add_pals_btn"><span>Me❤️‍🔥</span></div>}
					{/* <div className="ranking_strip_icons_row">
					</div> */}
				</div>
			<div style={!user.cheers&&!user.followers?{display:'none'}:{}} className="ranking_strip_attributes">
				<div className="ranking_strip_attribute">
					<img src={Cheers} alt="cheers" />
					<p>
						{user.cheers} <span>Cheers</span>
					</p>
				</div>
				<div className="ranking_strip_attribute">
					<img src={Friendship} alt="friendship" />
					<p>
						{user.followers} <span>followers</span>{" "}
					</p>
				</div>
			</div>
		</div>
	);
}

export default RankingStrip;
