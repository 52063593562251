import React, { useState } from "react";
import NavBar from "../components/NavBar";
import "../styles/App.css";
import Profile from "./profile/Profile";
import Connection from "./user-connection/Connection";
import { Switch, Route } from "react-router-dom";
import Ranking from "./ranking/Ranking";
import Footer from "./Footer";
import Feed from "../components/feed/Feed";
import DataContextProvider from "./DataContext";
import { ProtectedRoute } from "./ProtectedRoute";
import AdminPanel from "./admin-panel/AdminPanel";
import { DataContext } from "./DataContext";
import Help from "./Help";

function App() {
	const [showNotfications, setShowNotfications] = useState(false);

	return (
		<div className="App">
			<DataContextProvider>
				<NavBar
					showNotfications={showNotfications}
					setShowNotfications={setShowNotfications}
				/>
				<div onClick={() => setShowNotfications(false)}>
					<Switch>
						<Route
							exact
							path={"/"}
							render={(r) => <Connection />}
						/>
						<ProtectedRoute path={"/ranking"} component={Ranking} />
						<ProtectedRoute path={"/help"} component={Help} />
						<ProtectedRoute
							path={"/profile*"}
							component={Profile}
						/>
						<ProtectedRoute path={"/feed"} component={Feed} />
						<Route path={"/admin"} component={AdminPanel} />
					</Switch>
				</div>

				<Footer />
			</DataContextProvider>
		</div>
	);
}

export default App;
