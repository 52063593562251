import React,{useContext,useState} from 'react'
import { DataContext } from '.././DataContext'
import UserToManage from './UserToManage'
function UserManagement() {
	const {users,setUsers,componentsSearchCondition,currentUserData,deleteUser} = useContext(DataContext)
	return (
		<div className="users_management">
			{
				users
				.filter(
					componentsSearchCondition
				)
				.map(user=> {if(user.id != currentUserData.id)return<UserToManage deleteUser={deleteUser} setUsers={setUsers} user={user}/>})
			}
		</div>
	)
}

export default UserManagement
