import React,{useState,useEffect} from 'react'

function RankingButton({num,setCurrentUsers,users,sliceKey,focus,setButtonFocus,chosenRank,genderFilter}) {
	useEffect(()=>{
		if(num==1)
		setCurrentUsers(prev=>users.filter(genderFilter).filter((user) => user.userRank == chosenRank).slice((num*sliceKey)-sliceKey,sliceKey*num))
	},[genderFilter]) 
	return (
		<div onClick={()=>{
			setButtonFocus(num)
			setCurrentUsers(prev=>users.filter(genderFilter).filter((user) => user.userRank == chosenRank).slice((num*sliceKey)-sliceKey,sliceKey*num))}} 
			className="ranking_button_container shadow_hover" style={focus?{backgroundColor:"var(--blue)",color:'var(--yellow)'}:{}}>
			<p>
				{num}
			</p>
		</div>
	)
}

export default RankingButton
